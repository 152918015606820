const initialState = {
  context_dictionary: {
    account_credit_def: '',
    lms_points: '',
    coop_def: '',
    gift_card_credit: ''
  },
  logo: {
    url: ''
  },
  secondary_image: {
    url: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PORTAL':
      // TODO: REMOVE!!!
      return {
        ...action.payload
        // multiple_address_shipping: true
      }

    default:
      return state
  }
}
