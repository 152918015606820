import { FETCH_MANAGED_CUSTOMER_ADDRESSES } from '../actions/types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MANAGED_CUSTOMER_ADDRESSES:
      return action.payload || state;
    default:
      return state;
  }
};
