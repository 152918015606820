import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import EditAddressForm from './EditAddressForm'

const EditAddressModal = ({ isOpen, toggle, address, closeEditModal, addressType = 'delivery' }) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>Edit Address</ModalHeader>
    <ModalBody>
      {isOpen ? (
        <EditAddressForm
          newAddressMode={isOpen}
          onCancel={closeEditModal}
          address={address}
          afterEditAddress={closeEditModal}
          addressType={addressType}
        />
      ) : null}
    </ModalBody>
  </Modal>
)

export default EditAddressModal
