import PropTypes from 'prop-types'
import { useState, useEffect, useMemo } from 'react'
import { ShoppingCart } from 'react-feather'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import { compose } from 'redux'
import { deleteCartItem, clearSweetAlert, dispatchSweetAlert } from '../../actions'
import './headerCart.css'
import HeaderCartItemsList from '../Cart/HeaderCartItemsList'

/**
 * Cart Component shown as a popup when hovering the cart icon on the header
 *
 * @param {object} props - Props passed to the Cart component
 * @param {Array} props.cartItems - List of items in the cart
 * @param {function} props.deleteCartItem - Action to delete a cart item
 * @param {function} props.dispatchSweetAlert - Action to dispatch a sweet alert
 * @param {function} props.clearSweetAlert - Action to clear a sweet alert
 * @param {object} props.location - React Router location object
 * @param {object} props.history - React Router history object
 * @returns {React.ReactElement} - Rendered Cart component
 */
const Cart = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [isButtonVisible, setIsButtonVisible] = useState(null)

  useEffect(() => {
    if (props.location.pathname === '/cart' && isOpen) {
      setIsOpen(false)
    }
  }, [props.location.pathname, isOpen])

  const handleClose = () => {
    setIsOpen(false)
    props.history.push('/cart')
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const cartIsEmpty = useMemo(
    () => !props.cartItems || (props.cartItems && props.cartItems.length === 0),
    [props.cartItems]
  )

  const cartItemsCount = useMemo(() => (props.cartItems?.length > 0 ? props.cartItems.length : null), [props.cartItems])

  const buttonStyles = useMemo(
    () => ({
      minWidth: '51px'
    }),
    []
  )

  const modifiers = useMemo(
    () => ({
      offset: {
        enabled: true,
        offset: '-500px'
      },
      computeStyle: {
        enabled: true,
        y: 'right'
      }
    }),
    []
  )

  const goToCartButtonStyles = useMemo(
    () => ({
      width: 'calc(100% - 2rem)',
      height: '100%',
      borderRadius: '3px'
    }),
    []
  )

  return (
    <>
      <Button
        id="cart-popover"
        ref={el => {
          setIsButtonVisible(!!el)
        }}
        type="button"
        color="inherit"
        variant="contained"
        className="icon-btn"
        style={buttonStyles}
      >
        <ShoppingCart fill="currentColor" style={{ color: 'white' }} />
        {cartItemsCount && <span className="cart-icon-badge">{cartItemsCount}</span>}
      </Button>

      {isButtonVisible ? (
        <Popover
          trigger="hover click"
          boundariesElement="viewport"
          positionFixed={true}
          placement="bottom-start"
          target="cart-popover"
          isOpen={isOpen}
          toggle={toggle}
          modifiers={modifiers}
          className="cart-popper-shadow"
        >
          <PopoverHeader className="p-0 mf-sticky">
            {!cartIsEmpty ? (
              <Button onClick={handleClose} className="btn mf-primary-btn m-3" style={goToCartButtonStyles}>
                Go To Cart
              </Button>
            ) : (
              <p className="p-3 m-0">Your cart is empty</p>
            )}
          </PopoverHeader>

          {!cartIsEmpty && (
            <PopoverBody>
              <HeaderCartItemsList isCheckout={true} mini={true} />
            </PopoverBody>
          )}
        </Popover>
      ) : null}
    </>
  )
}

Cart.propTypes = {
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      quantity: PropTypes.number,
      price: PropTypes.number
    })
  ),
  deleteCartItem: PropTypes.func.isRequired,
  dispatchSweetAlert: PropTypes.func.isRequired,
  clearSweetAlert: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  portal: state.portal,
  customerGroup: state.customerGroup,
  cartItems: state.cartItems,
  currentLocation: state.currentLocation
})

export default compose(
  withRouter,
  connect(mapStateToProps, {
    deleteCartItem,
    dispatchSweetAlert,
    clearSweetAlert
  })
)(Cart)
