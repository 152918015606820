import { addressLabel, parameterizeUnderscore, resolveAddresses } from '../../util/utils'
import { connect } from 'react-redux'
import './AddressList.css'
import List from 'devextreme-react/list'
import { useMemo } from 'react'
import { Row, Col, Button } from 'reactstrap'

// Single address block
const AddressBlock = ({ address, onDelete, onEdit }) => {
  return (
    <Row>
      <Col xs={8} style={{ paddingRight: 0 }}>
        {addressLabel(address)}
      </Col>
      <Col xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button className="mf-outline-sm-btn" onClick={() => onEdit(address)}>
          Edit
        </Button>
        &nbsp;&nbsp;
        <Button className="mf-outline-danger-sm-btn" onClick={() => onDelete(address.id, 'customer')}>
          Delete
        </Button>
      </Col>
    </Row>
  )
}

// List of addresses with edit and delete buttons
const AddressList = ({
  addresses,
  onDelete,
  onEdit,
  addressType,
  customerAddresses,
  managedCustomerAddresses,
  portal
}) => {
  const customer_addresses = useMemo(() => {
    const resvoledAddresses = resolveAddresses(customerAddresses, managedCustomerAddresses, portal, onEdit, addressType)

    if (addressType === 'delivery' && !onEdit) {
      return resvoledAddresses.filter(address => address.address_type === 'delivery')
    }
    return resvoledAddresses
  }, [customerAddresses, managedCustomerAddresses, portal, onEdit, addressType])

  return (
    <div style={{ padding: '.75em' }}>
      <List
        items={customer_addresses}
        height={'auto'}
        itemRender={address => <AddressBlock address={address} onDelete={onDelete} onEdit={onEdit} />}
        noDataText="No Saved Addresses To Display"
      />
    </div>
  )
}

const mapStateToProps = state => {
  const portal = state.portal
  const customerAddresses = state.customer_addresses
  const managedCustomerAddresses = state.managed_customer_addresses
  return {
    currentUser: state.currentUser,
    customerAddresses,
    managedCustomerAddresses,
    portal
  }
}

export default connect(mapStateToProps, {})(AddressList)
