const INITIAL_STATE = {
  signIn: false,
  customerAddresses: false,
  session: true,
  pages: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_CUSTOMER_ADDRESSES_LOADING':
      return { ...state, customerAddresses: action.payload }
    case 'SET_LOADING':
      return { ...state, [action.payload.key]: action.payload.bool }
    default:
      return state
  }
}
