import moment from 'moment-timezone'
import NumericInput from 'react-numeric-input'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { InputNumber, InputGroup } from 'rsuite'
import {
  resolveUnitPrice,
  setMaxQuantityLoading,
  setMultiAddressSelection,
  setCheckoutLoading
} from '../Checkout/checkoutFunctions'
import MultiAddressSelection from '../Checkout/MultiAddressSelection'
import dollar_price from '../general/DollarPrice'
import placeholder from '../Shop/placeholder-image.jpg'
import { RenderLineItemExtraInfo, RenderCartItemInfo } from './index'

const CartItemCard = ({
  cartItem,
  errors,
  maxQtyProductReached,
  currentLocation,
  portal,
  cartItems,
  isCheckout,
  checkout,
  maxQuantityLoading,
  mini,
  index,
  tooltipOpen,
  setTooltipOpen,
  queryLoading,
  setQueryLoading,
  query,
  setQuery,
  cartItemQuantities = {},
  setCartItemQuantities,
  setDeleteConfirmationItem,
  setCheckoutLoading
}) => {
  const { priceData, cart_item_id, product_id, images, show_backorder_text } = cartItem
  const isMultiAddress = portal.multiple_address_shipping
  const thisCartItemQty = parseInt(cartItemQuantities[cart_item_id])

  // get cart item's base unit price, before applied credits
  const cartItemUnitPrice = resolveUnitPrice(priceData)
  const cartItemUnitSetupCharge = priceData['setup_charge'] ? priceData['setup_charge'] : 0

  // the total of user's coop credits that have been APPLIED to this item's subtotal
  const totalAppliedCoopCredits =
    priceData.appliedCredits && priceData.appliedCredits.coop ? priceData.appliedCredits.coop : 0

  const toggleArtworkPreview = (key, e) => {
    if (key) {
      setTooltipOpen({ ...tooltipOpen, [key]: !tooltipOpen[key] })
    }
  }

  const handleUpdateQuantity = e => {
    setMaxQuantityLoading(true)
    setCheckoutLoading(true)
    const incrementDecrementValue = cartItem.enable_qty_increments ? parseInt(cartItem.qty_increments) : 1
    let newQty = thisCartItemQty
    const operator = typeof e === 'object' ? e.target.innerText : null
    if (operator === '-') {
      newQty -= incrementDecrementValue
    } else if (operator === '+') {
      newQty += incrementDecrementValue
    } else {
      // if user is typing in input field, e is the value
      newQty = parseInt(e)
    }

    // first set state for responsiveness
    setCartItemQuantities({ ...cartItemQuantities, [cart_item_id]: newQty })
    // then put query in queue
    setQuery({ cartItemId: cart_item_id, qty: newQty })
  }

  const lineItemHasErrors =
    (maxQtyProductReached && maxQtyProductReached[product_id] === true) ||
    (errors.stock && errors.stock.find(ci => ci.cart_item_id === cart_item_id))

  // FOR HEADER CART
  if (mini) {
    return (
      <tr
        key={index}
        className="cartItemRow"
        id={`cart-item-row-${cartItem.cart_item_id}`}
        style={
          errors.stock && errors.stock.find(ci => ci.cart_item_id === cartItem.cart_item_id)
            ? { background: 'rgba(255, 0, 0, 0.21)' }
            : {}
        }
      >
        {/* THUMBNAIL IMAGE */}
        <td className="cartItemThumbnail" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <img
            src={cartItem.images && cartItem.images.length > 0 ? cartItem.images[0].small : placeholder}
            alt="cart-item"
            width="100"
          />
        </td>

        {/* NAME AND INFO */}
        <td className="cartItemDetails">{RenderCartItemInfo(cartItem, mini)}</td>

        {/* QUANTITY */}
        <td
          className="lineItemQuantity"
          style={{ maxWidth: '100px', minWidth: '90px', textAlign: 'center', verticalAlign: 'middle' }}
        >
          <span>
            {mini ? 'x' : null}
            {cartItem.quantity}
          </span>
        </td>

        {/* LINE ITEM SUBTOTAL */}
        <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
          {!isCheckout || (isCheckout && checkout.creditsUsed.coopCredits)
            ? dollar_price(cartItemUnitPrice * cartItem.quantity + cartItemUnitSetupCharge - totalAppliedCoopCredits)
            : // on checkout page, only include credits if user has it checked
              dollar_price(cartItemUnitPrice * cartItem.quantity + cartItemUnitSetupCharge)}
        </td>
      </tr>
    )
  }
  // END FOR HEADER CART

  // FOR CART PAGE
  return (
    <Row
      id={`cart-item-row-${cart_item_id}`}
      className="cart-flex white-background box-shadow"
      style={{
        background: lineItemHasErrors ? 'rgba(255, 0, 0, 0.21)' : '',
        paddingRight: '15px'
      }}
    >
      {/* Column 1 - THUMBNAIL IMAGE */}
      <Col xs="12" md="12" lg="3" className="cart-thumbnail-flex p-0">
        <Link to={`/product/${product_id}/${portal.id}/${currentLocation.id}`} className="w-100">
          <img
            className="cart-thumbnail-flex mf-img-container br-8"
            src={images && images.length > 0 ? images[0].small : placeholder}
            alt="cart-item"
            width="100"
          />
        </Link>
      </Col>

      {/* Column 2 - PRODUCT INFO */}
      <Col xs="12" md="12" lg="9" className="cartItemDetails">
        {/* NAME AND DESCRIPTION */}
        <Row className="p-075">
          <Col xs="12" md="12" lg="8" className="cart-product-info">
            {/* Product Name */}
            <Link to={`/product/${cartItem.product_id}/${portal.id}/${currentLocation.id}`}>
              <h4>{cartItem.name}</h4>
            </Link>

            <div id="cartItemAdditionalInfo">
              {/* Backorder Text */}
              {cartItem && show_backorder_text && (
                <span style={{ fontSize: '0.95em' }} className="danger-color">
                  {portal.backorder_text}
                </span>
              )}

              {/* Digital Proofing Info */}
              <div className="dpInfoTopCart">
                {cartItem &&
                cartItem.is_digital_proofing &&
                cartItem.digitalProofing[0] &&
                cartItem.digitalProofing[0].approved_date ? (
                  <>
                    {`Approved ${moment
                      .tz(cartItem.digitalProofing[0].approved_date, moment.tz.guess())
                      .format('MMMM Do YYYY, h:mm a')}`}
                    <br />
                  </>
                ) : null}
                {/* TODO: this should request preview from server not link to file */}
                {cartItem.digitalProofing && !cartItem.error ? (
                  <>
                    <a
                      href={
                        cartItem.digitalProofing && cartItem.digitalProofing[0]
                          ? cartItem.digitalProofing[0].artwork.pdf_preview.url
                          : '#'
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Proofing Preview
                    </a>
                    <p>
                      <small>
                        While the preview image is set to a lower resolution, the final printable version will be in
                        high resolution.
                      </small>
                    </p>
                  </>
                ) : null}
              </div>

              {/* Template Info */}
              {cartItem && cartItem.template
                ? RenderLineItemExtraInfo(
                    cartItem.template.template_fields,
                    'templateFields',
                    cartItem.cart_item_id,
                    mini,
                    tooltipOpen,
                    toggleArtworkPreview
                  )
                : null}

              {/* Configurable Info */}
              {cartItem && cartItem.flags && cartItem.flags.is_configurable
                ? RenderLineItemExtraInfo(
                    cartItem.variants,
                    'configurable',
                    cartItem.cart_item_id,
                    mini,
                    tooltipOpen,
                    toggleArtworkPreview
                  )
                : null}

              {/* Bundle Info */}
              {cartItem && cartItem.is_bundle === true
                ? RenderLineItemExtraInfo(
                    cartItem.bundle.products,
                    'bundleProduct',
                    cartItem.cart_item_id,
                    mini,
                    tooltipOpen,
                    toggleArtworkPreview
                  )
                : null}

              {/* Artwork Info (Simple Product) */}
              {cartItem && cartItem.flags && cartItem.flags.is_artwork
                ? RenderLineItemExtraInfo(
                    cartItem.artwork.nested_data,
                    'artwork',
                    cartItem.cart_item_id,
                    mini,
                    tooltipOpen,
                    toggleArtworkPreview
                  )
                : null}
            </div>
          </Col>

          {/* LINE ITEM PRICE && QUANTITY && DELETE */}
          <Col xs="12" md="12" lg="4" className="lineItemPrice lineItemPriceText" style={{ verticalAlign: 'middle' }}>
            {/* if product is coop eligible */}
            {currentLocation.show_price ? (
              <p className="m-0">
                {dollar_price(cartItemUnitPrice)}
                {'/ea'}
              </p>
            ) : null}

            {currentLocation.show_price && cartItem.priceData.tierPriceText ? (
              <sub style={{ bottom: '0' }}>
                <em>{cartItem.priceData.tierPriceText}</em>
              </sub>
            ) : null}

            {/* QUANTITY */}
            {isCheckout ? (
              <span className="w-inherit">
                {mini ? 'x' : null}
                <p className="m-0">
                  <strong>QTY:</strong> {cartItem.quantity}
                </p>
              </span>
            ) : (
              <InputGroup style={{ margin: '7px 0px' }}>
                <NumericInput
                  onChange={handleUpdateQuantity}
                  min={
                    cartItem.minimum_qty_allowed_in_shopping_cart
                      ? cartItem.minimum_qty_allowed_in_shopping_cart
                      : cartItem.enable_qty_increments
                        ? cartItem.qty_increments
                        : 1
                  }
                  max={1000000000}
                  step={cartItem.enable_qty_increments ? cartItem.qty_increments : 1}
                  value={cartItemQuantities[cartItem.cart_item_id]}
                  precision={0}
                  mobile
                  className="qtyinputbox min-height-3125 w-100"
                  strict
                  snap
                />
              </InputGroup>
            )}

            {/* DELETE BUTTON */}
            {isCheckout ? null : (
              <sub
                className="red-text text-right"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setDeleteConfirmationItem(cartItem)}
              >
                Remove Item
              </sub>
            )}
            {maxQtyProductReached && maxQtyProductReached[cartItem.product_id] ? (
              <>
                <br />
                <sub style={{ textAlign: 'center' }} className="red-text">
                  You have exceeded the maximum allowed qty for this product.
                </sub>
              </>
            ) : null}
          </Col>
        </Row>

        <hr className="m-0" />

        {/* PRICE TOTALS */}

        {/* Setup Charge */}
        <Row className="p-075">
          <Col xs={12} sm={7} md={'fluid'}>
            {cartItemUnitSetupCharge > 0 ? (
              <span>
                <h6 className="setup-charge-cart">
                  Total Price Includes A {dollar_price(cartItemUnitSetupCharge)} Setup Charge
                </h6>
              </span>
            ) : null}
          </Col>

          {/* Cart Item Price/Co Op Price/ Discounts */}
          <Col xs={12} sm={5} md={'fluid'}>
            {/* If this cart item has coop, display eligible reduction */}
            {cartItem.priceData.coop_enabled ? (
              <>
                <Row className="active-color cart-price-box">
                  <Col xs="12" lg="12" className="cart-price-container">
                    <h5 className="m-0">
                      {/* if on checkout page, this should only display the amount of credits being used for this product */}
                      {!isCheckout || (isCheckout && checkout.creditsUsed.coopCredits)
                        ? dollar_price(
                            cartItemUnitPrice * cartItem.quantity + cartItemUnitSetupCharge - totalAppliedCoopCredits
                          )
                        : // on checkout page, only include credits if user has it checked
                          dollar_price(cartItemUnitPrice * cartItem.quantity + cartItemUnitSetupCharge)}
                    </h5>
                  </Col>
                </Row>

                {/* Only show "Saved You" on checkout page when account credits are being used */}
                {!isCheckout || (isCheckout && checkout.creditsUsed.coopCredits) ? (
                  <Row className="cart-price-box">
                    <Col xs="12" lg="12" className="p-0">
                      <p className="w-100 m-0 cart-price-container">
                        {portal.context_dictionary.coop_abbreviation} Saved You: {dollar_price(totalAppliedCoopCredits)}
                      </p>
                    </Col>
                  </Row>
                ) : null}
              </>
            ) : (
              <Row className="cart-price-box">
                <Col xs="12" lg="12" className="p-0">
                  <h5 className="m-0">
                    {cartItem.flags.bundle_type === 'Breakable'
                      ? dollar_price(cartItemUnitPrice * cartItem.quantity + cartItemUnitSetupCharge)
                      : dollar_price(cartItemUnitPrice * cartItem.quantity + cartItemUnitSetupCharge)}
                  </h5>
                </Col>
              </Row>
            )}
            {/* if other discounts have been applied, show here (for debu gging) */}
            {cartItem.priceData.discounts && !_.isEmpty(cartItem.priceData.appliedDiscounts) ? (
              <Row>
                <Col>Discounts applied: {dollar_price(cartItem.priceData.discounts.discount)}</Col>
              </Row>
            ) : null}
          </Col>
        </Row>

        {cartItem.shippable_type === 'Digital Delivery' ? (
          <p style={{ margin: 'auto', textAlign: 'left', fontSize: '0.8em', marginBottom: '0px' }}>
            <em>Digital downloads will be sent to your email address.</em>
          </p>
        ) : null}

        {/* Multi-Addresss Dropdown & Quantity */}
        {isMultiAddress && isCheckout ? <MultiAddressSelection cartItem={cartItem} /> : null}
      </Col>
    </Row>
  )
}

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation,
    portal: state.portal,
    customerGroup: state.customerGroup,
    cartItems: state.cartItems,
    currentLocation: state.currentLocation,
    cart_items_loading: state.productPage.cart_items_loading,
    maxQuantities: state.maxQuantities,
    errors: state.checkout.errors,
    youPay: state.checkout.youPay,
    checkout: state.checkout,
    userCredits: state.userCredits,
    maxQuantityLoading: state.checkout.maxQuantityLoading,
    currentUser: state.currentUser,
    customer_addresses: state.customer_addresses,
    managed_customer_addresses: state.managed_customer_addresses
  }
}

export default connect(mapStateToProps, {
  setMultiAddressSelection,
  setCheckoutLoading
})(CartItemCard)
