import { useState, useEffect } from 'react'
import { Plus, Minus } from 'react-feather'
import PhoneInput from 'react-phone-number-input'
import { connect } from 'react-redux'
import { Input, Label, Col, InputGroup, Button, Form, FormGroup, Alert } from 'reactstrap'
import 'react-phone-number-input/style.css'
import { updateCustomerAddress, validateAddress } from '../../actions'
import supportedCountries, { getAllCountries } from '../../configs/supportedCountries'
import { validateForm, validateField, createLabels } from '../../util/forms'
import { titleizeField, validateAddressLines } from '../../util/utils'
import CountrySelect from '../addresses/CountrySelect'
import StateProvinceSelect from '../addresses/StateProvinceSelect'

const formLabelBsWidth = 4
const formInputBsWidth = 8

const EditAddressForm = ({
  address,
  portal,
  validateAddress,
  onCancel,
  updateCustomerAddress,
  afterEditAddress = () => {},
  afterCreateAddress = () => {},
  addressType = 'delivery'
}) => {
  const [showAddress2, setAddress2] = useState(false)
  const defaultKey = addressType === 'delivery' ? 'default_shipping' : 'default_billing'
  const [addressData, setAddressData] = useState({
    id: address.id,
    full_name: address.full_name,
    address_line_1: address.address_line_1,
    address_line_2: address.address_line_2,
    city: address.city,
    state: address.state,
    country: address.country,
    zip_code: address.zip_code,
    address_phone: address.address_phone,
    country_code: address.country_code,
    [defaultKey]: address[defaultKey]
  })
  const [loading, setLoading] = useState(false)

  const [formErrors, setFormErrors] = useState({
    full_name: null,
    address_line_1: null,
    address_line_2: null,
    company_name: null,
    city: null,
    state: null,
    country: null,
    zip_code: null,
    address_phone: null
  })

  useEffect(() => {
    setAddressData({
      id: address.id,
      full_name: address.full_name,
      address_line_1: address.address_line_1,
      address_line_2: address.address_line_2,
      company_name: address.company_name,
      city: address.city,
      state: address.state,
      country: address.country,
      zip_code: address.zip_code,
      address_phone: address.address_phone,
      country_code: address.country_code,
      [defaultKey]: address[defaultKey]
    })
  }, [address])

  const toggleAddress2 = () => setAddress2(!showAddress2)
  const handleMakeDefault = () => {
    setAddressData({ ...addressData, default_shipping: !addressData.default_shipping })
  }

  const PhoneInputField = (international = false) => {
    return (
      <FormGroup row>
        <Label sm={formLabelBsWidth} className="address-format-label">{`Phone`}</Label>
        <Col sm={formInputBsWidth} style={{ alignSelf: 'center' }}>
          <PhoneInput
            name="address_phone"
            value={addressData.address_phone}
            international={portal.international_shipping} //* make this dynamic based on portal setting */
            withCountryCallingCode={portal.international_shipping}
            onChange={handleChangePhone}
            defaultCountry={addressData.country_code ? addressData.country_code : 'US'}
            countries={portal.international_shipping ? countries.map(country => country.country_code) : ['US']}
          />
          {formErrors.address_phone && <span className="text-danger small">{formErrors.address_phone}</span>}
        </Col>
      </FormGroup>
    )
  }

  const handleChange = event => {
    const label = event.target.labels?.[0]?.textContent.trim() || event.target.name
    const { name, value } = event.target

    if (name === 'address_line_1' || name === 'address_line_2') {
      setAddressData(prevAddressData => ({
        ...prevAddressData,
        [name]: value
      }))

      const updatedErrors = validateAddressLines(
        name,
        name === 'address_line_1' ? value : addressData.address_line_1,
        name === 'address_line_2' ? value : addressData.address_line_2,
        formErrors
      )

      setFormErrors(updatedErrors)
      return
    }

    const error = validateField(name, value, portal.international_shipping, label)

    setFormErrors(prevErrors => ({
      ...prevErrors,
      [name]: error
    }))

    setAddressData(prevAddressData => ({
      ...prevAddressData,
      [name]: value
    }))
  }

  const handleChangeCountry = countryObject => {
    setFormErrors(prevErrors => ({
      ...prevErrors,
      city: '',
      zip_code: ''
    }))

    setAddressData({
      ...addressData,
      country: countryObject.name,
      country_code: countryObject.country_code
    })
  }
  const handleChangePhone = value => {
    setFormErrors(prevErrors => ({
      ...prevErrors,
      address_phone: ''
    }))
    setAddressData({ ...addressData, address_phone: value })
  }

  const handleSaveAddress = address => {
    address.is_shipping = true
    updateCustomerAddress({
      values: address,
      address_id: address.id,
      callback: afterEditAddress
    })
  }

  const handleUpdateAddress = () => {
    const labels = createLabels(selectedCountry)
    const isInternationalShipping = portal.international_shipping
    const { errors, isValid } = validateForm(addressData, isInternationalShipping, labels, formErrors)

    if (!isValid) {
      setFormErrors(errors)
      return
    }

    validateAddress({
      addressData,
      onSaveAddress: handleSaveAddress,
      callbackFunction: afterCreateAddress,
      isInternationalShipping: portal.international_shipping
    }).then(() => {
      setLoading(false)
    })
  }

  const countries = supportedCountries(portal.supported_countries)
  const selectedCountry = getAllCountries().find(country => country.country_code === addressData.country_code) || {
    labels: {}
  }

  return (
    <Form style={{ paddingRight: '15px' }}>
      <br />
      <TextInputField
        fieldName="full_name"
        value={addressData.full_name}
        handleChange={handleChange}
        error={formErrors['full_name'] ?? null}
      />
      {PhoneInputField()}

      {/* Country Select */}
      <FormGroup row>
        <Label sm={formLabelBsWidth} className="address-format-label" for="country">
          Country
        </Label>
        <Col sm={formInputBsWidth} style={{ alignSelf: 'center' }}>
          <CountrySelect handleChange={handleChangeCountry} countryCode={addressData.country_code} />
        </Col>
      </FormGroup>

      <TextInputField
        fieldName="company_name"
        value={addressData.company_name}
        handleChange={handleChange}
        error={formErrors['company_name'] ?? null}
      />

      <TextInputField
        fieldName="address_line_1"
        value={addressData.address_line_1}
        handleChange={handleChange}
        error={formErrors['address_line_1'] ?? null}
      />

      {showAddress2 ? null : (
        <FormGroup row>
          <Col sm={formLabelBsWidth}></Col>
          <Col sm={formInputBsWidth} style={{ alignSelf: 'center' }}>
            <p onClick={toggleAddress2} style={{ cursor: 'pointer', marginBottom: 0 }}>
              <Plus size={16} />
              &nbsp;&nbsp;<span style={{ textDecoration: 'underline' }}>Add Address Line 2</span>
            </p>
          </Col>
        </FormGroup>
      )}

      {showAddress2 ? (
        <TextInputField
          fieldName="address_line_2"
          value={addressData.address_line_2}
          handleChange={handleChange}
          error={formErrors['address_line_2'] ?? null}
          disable={formErrors['address_line_1'] ? true : false}
        />
      ) : null}

      <TextInputField
        fieldName="zip_code"
        label={selectedCountry.labels.postcode}
        value={addressData.zip_code}
        handleChange={handleChange}
        error={formErrors['zip_code'] ?? null}
      />
      <TextInputField
        fieldName="city"
        label={selectedCountry.labels.city}
        value={addressData.city}
        handleChange={handleChange}
        error={formErrors['city'] ?? null}
      />

      {/* State/Province Select */}
      {selectedCountry.labels && selectedCountry.labels.region === 'N/A' ? null : (
        <FormGroup row>
          <Label sm={formLabelBsWidth} className="address-format-label" for="state">
            {selectedCountry.labels.region}
          </Label>
          <Col sm={formInputBsWidth} style={{ alignSelf: 'center' }}>
            <StateProvinceSelect
              countryCode={addressData.country_code}
              handleChangeState={stateProvince => setAddressData({ ...addressData, state: stateProvince.value })}
              stateValue={addressData.state}
            />
          </Col>
        </FormGroup>
      )}

      {addressType === 'delivery' ? (
        <FormGroup row>
          <Col sm={12}>
            <InputGroup style={{ float: 'right', width: 'auto' }}>
              <Label check className="address-format-label">
                <Input type="checkbox" onChange={handleMakeDefault} checked={addressData.default_shipping} />
                Set as Default Shipping Address
              </Label>
            </InputGroup>
          </Col>
        </FormGroup>
      ) : null}

      <FormGroup row>
        <Col sm={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button className="mf-primary-btn" onClick={handleUpdateAddress} disabled={loading}>
            Update Address
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button className="mf-outline-btn" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>
        </Col>
      </FormGroup>
    </Form>
  )
}

const mapStateToProps = state => {
  return {
    userHasNoAddresses: state.checkout.userHasNoAddresses,
    portal: state.portal
  }
}

export default connect(mapStateToProps, { updateCustomerAddress, validateAddress })(EditAddressForm)

export const TextInputField = ({
  label,
  fieldName,
  labelWidth = formLabelBsWidth,
  inputWidth = formInputBsWidth,
  value,
  handleChange,
  error,
  disable = false
}) => {
  if (!label && fieldName) label = titleizeField(fieldName)
  return (
    <FormGroup row>
      <Label sm={labelWidth} className="address-format-label" for={fieldName}>
        {label}
      </Label>
      <Col sm={inputWidth} style={{ alignSelf: 'center' }}>
        <Input
          className={`w-100 ${error ? 'border-danger' : ''}`}
          style={{ width: '100%' }}
          type="text"
          name={fieldName}
          id={fieldName}
          onChange={handleChange}
          value={value}
          disabled={disable}
        />
        {error && <span className="text-danger small">{error}</span>}
      </Col>
    </FormGroup>
  )
}
