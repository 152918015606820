import _ from 'lodash'
import { React, useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { parameterizeUnderscore, resolveAddresses } from '../../util/utils'
import LoadingSpinner from '../general/LoadingSpinner'
import FormAddressWrapper from '../Shop/DigitalProofingForms/FormAddressWrapper'
import CheckoutAddressPanel from './CheckoutAddressPanel'
import { setCustomerAddress, setBillingAddress } from './checkoutFunctions'
import NewAddressForm from './NewAddressForm'

const CheckoutAddressSelection = props => {
  // hub/door - never show on DP
  // hub is only for shipping to, doors are for everything else
  const [newAddressMode, setNewAddressMode] = useState(false)
  const [shippingAddresses, setShippingAddresses] = useState([])
  const [billingAddresses, setBillingAddresses] = useState([])
  const [manageAddresses, setManageAddresses] = useState(false)

  // after cart is loaded, check for default address
  useEffect(() => {
    if (props.checkout.cart_loading === false) {
      const defaultAddress = props.customer_addresses.find(address => address.default_shipping === true)

      if (defaultAddress) {
        const isSysMangNotAddressBook = defaultAddress.is_system_managed && !props.portal?.managed_address_book
        const isNotSysMangAddressBook =
          !defaultAddress.is_system_managed &&
          props.portal?.managed_address_book &&
          (!props.portal?.hybrid_address || props.customerGroup.disable_hybrid_shipping)

        if (!(isSysMangNotAddressBook || isNotSysMangAddressBook)) {
          props.setCustomerAddress(defaultAddress)
          props.setBillingAddress(defaultAddress)
        }
      }
    }
  }, [props.checkout.cart_loading])

  // watch for managed/trad address updates and consolidate them into a single array
  useEffect(() => {
    // resolve which addresses should go to list
    const traditionalAddresses = props.customer_addresses
    const managedAddresses = Object.values(props.managed_customer_addresses)

    let addressesForList = []

    addressesForList = resolveAddresses(traditionalAddresses, managedAddresses, props.portal)

    const orderAddresses = orderById(addressesForList)
    setShippingAddresses(orderAddresses)
    const allBillingAddresses = [...traditionalAddresses, ...managedAddresses]

    const uniqueBillingAddresses = Object.values(
      allBillingAddresses.reduce((acc, curr) => {
        acc[curr.id] = curr
        return acc
      }, {})
    )
    // billing list will receive all addresses
    const orderedBillingAddresses = orderById(uniqueBillingAddresses)
    setBillingAddresses(orderedBillingAddresses)
  }, [props.customer_addresses, props.managed_customer_addresses])

  const orderById = (adds, order = 'desc') => {
    return _.orderBy(adds, 'id', order)
  }

  // Hide shipping address if all items are Digital customer
  const allItemsDigitalDelivery = props.cartItems.every(cartItem => cartItem.shippable_type === 'Digital Delivery')

  // Hide billing address if it's the same as customer, if youPay is 0,
  // or if no customer address AND not all items are digital - in that case we will need the billing address only
  const hideBillingAddress =
    (props.checkout.youPay === 0 && !props.requireBillingAddressForFreeOrders) || // if a billing address is selected and is the same as shipping address
    (props.selectedBillingAddress.id && props.selectedBillingAddress.id === props.selectedCustomerAddress.id) ||
    props.useAsBilling

  const hideShippingAddress = allItemsDigitalDelivery

  if (props.cart_loading) return <LoadingSpinner />

  return (
    <div className="box-shadow white-background m-0">
      {hideShippingAddress ? null : (
        <>
          <div className="ckout-header">
            <h5 className="m-0">{newAddressMode === true ? 'Add New ' : 'Shipping '} Address</h5>
          </div>
          <Container>
            <Row>
              <Col>
                {shippingAddresses.length === 0 && props.portal.managed_address_book !== true ? (
                  <NewAddressForm
                    newAddressMode={newAddressMode}
                    setNewAddressMode={setNewAddressMode}
                    afterCreateAddress={props.setCustomerAddress}
                  />
                ) : (
                  <CheckoutAddressPanel
                    addressType="delivery"
                    addresses={shippingAddresses}
                    newAddressMode={newAddressMode}
                    manageAddresses={manageAddresses}
                  />
                )}
                <FormAddressWrapper
                  manageAddresses={manageAddresses}
                  setManageAddresses={setManageAddresses}
                  addressType="delivery"
                  afterCreateAddress={props.setCustomerAddress}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}

      {hideBillingAddress ? null : (
        <div className="m-0">
          <div className="ckout-header">
            <h5 className="m-0">Billing Address</h5>
          </div>
          <Container className="mb-20">
            <Row>
              <Col>
                {/* Billing addresses should contain both traditional and managed addresses */}
                <CheckoutAddressPanel
                  addressType="billing"
                  addresses={billingAddresses}
                  newAddressMode={newAddressMode}
                  manageAddresses={manageAddresses}
                />
                <FormAddressWrapper
                  manageAddresses={manageAddresses}
                  setManageAddresses={setManageAddresses}
                  addressType="billing"
                  afterCreateAddress={props.setBillingAddress}
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userCredits: state.userCredits,
    portal: state.portal,
    currentLocation: state.currentLocation,
    checkout: state.checkout,
    customer_addresses: state.customer_addresses,
    managed_customer_addresses: state.managed_customer_addresses,
    selectedBillingAddress: state.checkout.selectedBillingAddress,
    selectedCustomerAddress: state.checkout.selectedCustomerAddress,
    userHasNoAddresses: state.checkout.userHasNoAddresses,
    cart_loading: state.checkout.cart_loading,
    customerGroup: state.customerGroup,
    cartItems: state.cartItems,
    currentUser: state.currentUser,
    requireBillingAddressForFreeOrders: state.currentLocation.require_billing_address_for_free_orders,
    customerAddressesLoading: state.loading.customerAddresses,
    useAsBilling: state.checkout.useAsBilling
  }
}

export default connect(mapStateToProps, {
  setCustomerAddress,
  setBillingAddress
})(CheckoutAddressSelection)
