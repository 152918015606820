import { useState, useEffect } from 'react'
import { Row, Col, Label, Button, Container, Modal, ModalBody } from 'reactstrap'
import Select from 'react-select'
import { connect } from 'react-redux'
import { resolveAddresses, addressLabelString, addressLabel, standardizeAddress } from '../../util/utils'
import _ from 'lodash'
import { setMultiAddressSelection, clearMultiAddressSelection, setErrors } from './checkoutFunctions'
import { X } from 'react-feather'
import NewAddressForm from './NewAddressForm'
import { useMediaQuery } from 'react-responsive'

const MultiAddressSelection = ({
  cartItem,
  portal,
  selectedCustomerAddress,
  setMultiAddressSelection,
  clearMultiAddressSelection,
  customer_addresses,
  managed_customer_addresses,
  setErrors,
  customerGroup
}) => {
  const [itemsAssignedToCustomAddresses, setItemsAssignedToCustomAddresses] = useState(cartItem.quantity)
  const [availableShippingAddresses, setAvailableShippingAddresses] = useState([])
  const [addressSelections, setAddressSelections] = useState({
    [0]: {
      address: selectedCustomerAddress,
      quantity: {
        value: cartItem.quantity,
        label: cartItem.quantity
      }
    }
  })
  // controls display of additional dropdown
  const [showNewSelection, setShowNewSelection] = useState(false)
  const [showAddressForm, setShowAddressForm] = useState(false) // use keyNum to store the selection this will go to
  const [hideAddressLabel, setHideAddressLabel] = useState({})

  const setMenuOpen = key => {
    setHideAddressLabel({
      ...hideAddressLabel,
      [key]: true
    })
  }
  const setMenuClosed = key => {
    setHideAddressLabel({
      ...hideAddressLabel,
      [key]: false
    })
  }

  // set checkout errors if quantity is overassigned/underassigned
  useEffect(() => {
    const assignedMatchesQuantity = itemsAssignedToCustomAddresses === cartItem.quantity
    const allSelectionsHaveAnAddress = Object.values(addressSelections).every(
      sel => sel.address && !_.isEmpty(sel.address)
    )
    const allSelectionsHaveQuantity = Object.values(addressSelections).every(sel => {
      // quantity may be valid if there is no `quantity` key, if it is the only one
      return (
        (!sel.quantity && Object.values(addressSelections).length === 1) || (sel.quantity && sel.quantity.value > 0)
      )
    })
    const isDigitalDelivery = cartItem.shippable_type === 'Digital Delivery'
    if (!isDigitalDelivery && (!assignedMatchesQuantity || !allSelectionsHaveAnAddress || !allSelectionsHaveQuantity)) {
      setErrors({ [`multiAddress_${cartItem.cart_item_id}`]: true })
    } else {
      setErrors({ [`multiAddress_${cartItem.cart_item_id}`]: false })
    }
  }, [itemsAssignedToCustomAddresses, addressSelections])

  const clearSelections = () => {
    clearMultiAddressSelection(cartItem.cart_item_id).then(() => {
      setAddressSelections({
        [0]: {
          address: selectedCustomerAddress,
          quantity: {
            value: cartItem.quantity,
            label: cartItem.quantity
          }
        }
      })
    })
  }

  // when managed/customer addresses change, merge them to set address options in dropdown
  useEffect(() => {
    resetAddressOptions()
  }, [customer_addresses, managed_customer_addresses])

  const resetAddressOptions = () => {
    const mergedAddresses = resolveAddresses(
      customer_addresses,
      managed_customer_addresses,
      portal,
      customerGroup.disable_hybrid_shipping
    )
    setAvailableShippingAddresses(mergedAddresses)
  }

  // when user changes main customer address, show confirmation dialogue
  useEffect(() => {
    if (selectedCustomerAddress && !_.isEmpty(selectedCustomerAddress)) {
      resetAddressOptions()
      clearSelections()
    }
  }, [selectedCustomerAddress])

  // when address selections change, send to redux
  useEffect(() => {
    // only send update if all selections are valid, i.e. address is present
    if (
      !_.isEmpty(addressSelections) &&
      Object.values(addressSelections).every(sel => sel.address && !_.isEmpty(sel.address))
    ) {
      setMultiAddressSelection(cartItem.cart_item_id, Object.values(addressSelections))
    }
  }, [addressSelections])

  // when selections change, keep track of total quantity assigned to custom addresses
  useEffect(() => {
    const itemsAccountedFor = getTotalItemsAssignedToCustomAddresses(Object.values(addressSelections))
    setItemsAssignedToCustomAddresses(itemsAccountedFor)
  }, [addressSelections])

  // when selections change, automatically add remaining quantity to new selection
  useEffect(() => {
    const selectionKeyNums = Object.keys(addressSelections)
    const lastKey = selectionKeyNums[selectionKeyNums.length - 1]
    // if there is more than one selection and the last selection has no quantity yet, add it
    if (lastKey > 0 && (!addressSelections[lastKey].quantity || !addressSelections[lastKey].quantity.value)) {
      const itemsAccountedFor = getTotalItemsAssignedToCustomAddresses(Object.values(addressSelections))
      const difference = cartItem.quantity - itemsAccountedFor
      const qtyRemaining = difference < 0 ? 0 : difference

      // only update if value is different than existing, or if there is no qty data, to prevent infinite loops
      if (!addressSelections[lastKey].quantity || qtyRemaining !== addressSelections[lastKey].quantity.value) {
        setAddressSelections({
          ...addressSelections,
          [lastKey]: {
            ...addressSelections[lastKey],
            quantity: { label: qtyRemaining, value: qtyRemaining }
          }
        })
      }
    }
  }, [addressSelections])

  const getTotalItemsAssignedToCustomAddresses = selections => {
    const totalQtyAssigned = selections.reduce((memo, selection, index) => {
      if (index === 0 && !selection.quantity) {
        return cartItem.quantity
      } else if (selection.quantity) {
        return selection.quantity.value + memo
      } else {
        return memo
      }
    }, 0)
    return totalQtyAssigned
  }

  // render options for quantity dropdown
  const multiAddressQtyOptions = (keyNum, selections) => {
    const numAlreadyAssigned = getTotalItemsAssignedToCustomAddresses(Object.values(selections))
    const integersFromZeroToQuantity = [...Array(cartItem.quantity).keys()]
    return integersFromZeroToQuantity
      .map(option => {
        // format options for react select
        return {
          value: option + 1,
          label: option + 1
        }
      })
      .filter(option => {
        // filter options for qty increments
        if (cartItem.qty_increments) {
          return option.value % cartItem.qty_increments === 0
        } else {
          return true
        }
      })
      .filter(option => {
        // filter options for minimum qty allowed
        if (cartItem.minimum_qty_allowed_in_shopping_cart) {
          // if there is a cart minimum qty, it's okay, let the user ship smaller qty
          return true
          // return option.value >= cartItem.minimum_qty_allowed_in_shopping_cart
        } else {
          return true
        }
      })
      .filter(option => {
        // filter options with higher qty than we have available (or less than zero)
        let valid = false
        const assignedPlusValue = numAlreadyAssigned + option.value
        const optionValueIsLessThanTotal = option.value <= cartItem.quantity && option.value > 0
        const optionPlusAssignedIsLessThanTotal = assignedPlusValue <= cartItem.quantity && assignedPlusValue > 0
        const numberOfSelections = Object.values(selections).length
        const existingSelectionValue =
          selections[keyNum] && selections[keyNum].quantity ? selections[keyNum].quantity.value : null // if no selection, fallback to qty
        const optionIsLessThanCurrentSelection = existingSelectionValue && option.value <= existingSelectionValue
        if (keyNum !== 0 && optionPlusAssignedIsLessThanTotal && optionValueIsLessThanTotal) {
          valid = true
        }
        // if only one selection, include all options
        else if (keyNum === 0 && numberOfSelections === 1) {
          valid = true
        }
        // an option will always be valid if it is less than the current selection
        else if (optionIsLessThanCurrentSelection) {
          valid = true
        }
        // if entire qty has been assigned, allow user to choose any option (will show message)
        else if (cartItem.quantity === itemsAssignedToCustomAddresses) {
          valid = true
        }
        // if no selection, and option is less than remaining qty
        else if (existingSelectionValue === null && optionPlusAssignedIsLessThanTotal) {
          valid = true
        } else {
          // allow user to choose any quantity
          valid = true
        }
        return valid
      })
  }

  const handleChangeMultiAddressQty = (option, keyNum) => {
    const qty = option.value

    // set selection at this key to have this quantity
    setAddressSelections({
      ...addressSelections,
      [keyNum]: {
        ...addressSelections[keyNum],
        quantity: {
          value: qty,
          label: qty
        }
      }
    })
  }

  // set an address to this key
  const handleSelectAddress = (address, key) => {
    if (address.value === '-1') {
      setShowAddressForm(key)
      return
    }

    const standardizedAddress = standardizeAddress(address)
    setAddressSelections({
      ...addressSelections,
      [key]: {
        ...addressSelections[key],
        address: standardizedAddress
      }
    })
  }

  const deleteSelection = keyNum => {
    const newAddressSelections = _.cloneDeep(addressSelections)
    deleteAndShift(newAddressSelections, keyNum)
    setAddressSelections(newAddressSelections)
  }

  function deleteAndShift(object, key) {
    delete object[key]
    while (++key in object) {
      object[key].stepNumber--
      object[key - 1] = object[key]
      delete object[key]
    }
  }

  const handleShowNewSelection = () => {
    // add an entry to addressSelections to display new selection,
    // but only if a selections has been made for the current last item in list
    // (prevents adding a million dropdowns)
    const newKeyNum = Object.keys(addressSelections).length
    setAddressSelections({
      ...addressSelections,
      [newKeyNum]: {
        // address: {}
      }
    })
    // set showNewSelection to false
    setShowNewSelection(false)
  }

  const afterCreate = addressData => {
    // const newlyCreatedAddress = _.orderBy(resp, 'id', 'desc')[0]
    // set address for this keyNum
    handleSelectAddress(addressData, showAddressForm)
    setShowAddressForm(false)
  }

  const finalKeyNum = Object.keys(addressSelections).length - 1

  // Media Queries
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 500px)' })

  // item may not be split if:
  // - there is only one quantity option for this cart item
  // - there is only one address option for this cart item
  const mayNotBeSplit =
    Object.keys(addressSelections).length === 1 && multiAddressQtyOptions(0, addressSelections).length === 1

  const qtyInSetText = cartItem.qty_in_set_text
    ? `Product is sold as ${cartItem.qty_in_set_text} of ${cartItem.qty_increments}`
    : ''

  // render an address/qty dropdown for each address selection, unless digital delivery
  if (cartItem.shippable_type === 'Digital Delivery') return null
  else
    return (
      <>
        <Row>
          <Col xs={isExtraSmallScreen ? 12 : 8}>
            <Label className="address-format-label">Ship To</Label>
          </Col>
          {isExtraSmallScreen ? null : (
            <Col xs={4} style={{ paddingLeft: 0 }}>
              <Label className="address-format-label">Qty</Label>
            </Col>
          )}
        </Row>

        {
          // Rows of address/qty dropdowns for making selections
          [...Array(Object.values(addressSelections).length).keys()].map(keyNum => {
            const hideDeleteButton = Object.keys(addressSelections).length === 1 || !addressSelections[keyNum]
            let qtyOptions = multiAddressQtyOptions(keyNum, addressSelections)
            const addressSelectedForThisKey = addressSelections[keyNum] ? addressSelections[keyNum].address : null
            // address options should include all addresses except ones already used
            // array of all address selected for this cart item:
            const selectedAddresses = Object.values(addressSelections)
              .map(selection => selection.address)
              .filter(e => !!e)

            let addressOptions = availableShippingAddresses
              .filter(address => {
                const currentAddressIsManagedAddress = !!address.address1

                // don't filter out address option if menu is open, unless
                // const menuIsOpen = hideAddressLabel[keyNum] === true

                // filter out selected addresses. match ID AND address line 1
                const idMatch = selectedAddresses.find(add => add.id === address.id)
                if (idMatch) {
                  let addressIsSelected
                  if (currentAddressIsManagedAddress) {
                    addressIsSelected = address.id === idMatch.id && address.address1 === idMatch.address1
                  } else {
                    addressIsSelected = address.id === idMatch.id && address.address_line_1 === idMatch.address_line_1
                  }
                  // remove the address from the dropdown if address has been selected, if it has not been selected for this key
                  const addressIsSelectedForThisKey =
                    addressSelectedForThisKey &&
                    addressSelectedForThisKey.id &&
                    addressSelectedForThisKey.id === address.id
                  return !(addressIsSelected && !addressIsSelectedForThisKey)
                } else {
                  return true
                }
              })
              // if there is an address selected, put this at the top of list
              .sort((a, b) => {
                const selectedAddress = addressSelections[keyNum].address
                if (selectedAddress && a.id === selectedAddress.id) {
                  return -1
                } else {
                  return 0
                }
              })

            // append option to add a new address from the dropdown, unless portal is managed_address
            const hideCreateShippingAddressOption =
              portal.managed_address_book && !portal.hybrid_address && !customerGroup.disable_hybrid_shipping
            if (!hideCreateShippingAddressOption) {
              addressOptions.unshift({ label: 'Add New Address...', value: '-1' })
            }

            // hide split button if last entry does not yet have a selection made

            const hideSplitButton = addressSelections[finalKeyNum] && !addressSelections[finalKeyNum].address

            const addNewFormatter = address => {
              // formatter for the above option
              return <div style={{ fontSize: '0.85em' }}>{address.label}</div>
            }

            // styles for react-select
            const menuStyles = (provided, state) => ({ ...provided, zIndex: 5 })
            const qtySelectStyles = (provided, state) => ({ ...provided, height: '100%', width: '100%' })
            const valueContainerStyles = (provided, state, keyNum) => {
              return {
                ...provided,
                overflow: 'visible'
                // height: '100%'
              }
            }
            const qtyContainerStyles = (provided, state) => ({
              ...provided,
              height: '100%',
              width: '100%'
            })

            // value for qty dropdown
            const qtyValue = addressSelections[keyNum] && addressSelections[keyNum].quantity

            const addressSelectValue =
              addressSelections &&
              addressSelections[keyNum] &&
              addressSelections[keyNum].address &&
              (addressSelections[keyNum].address.address_line_1 || addressSelections[keyNum].address.address1)
                ? addressSelections[keyNum].address
                : {}
            return (
              <Row key={keyNum} style={{ paddingBottom: '10px' }}>
                {/* Address Dropdown */}
                <Col xs={isExtraSmallScreen ? 12 : 8}>
                  <Select
                    styles={{
                      menu: menuStyles,
                      singleValue: (provided, state) => valueContainerStyles(provided, state, keyNum)
                    }}
                    options={addressOptions}
                    onChange={address => handleSelectAddress(address, keyNum)}
                    formatOptionLabel={address =>
                      address.value === '-1' ? addNewFormatter(address) : addressLabel(address, true)
                    }
                    getOptionLabel={addressLabelString}
                    getOptionValue={address => address.id}
                    controlShouldRenderValue={!(hideAddressLabel[keyNum] === true)}
                    placeholder={
                      hideAddressLabel[keyNum] === true ? 'Search for addresses' : 'Select or create shipping address'
                    }
                    value={addressSelectValue}
                    onMenuOpen={() => setMenuOpen(keyNum)}
                    onMenuClose={() => setMenuClosed(keyNum)}
                    isClearable={false}
                  />
                </Col>

                {isExtraSmallScreen ? (
                  <Col xs={12} style={{ height: '5px' }}>
                    {' '}
                  </Col>
                ) : null}

                {isExtraSmallScreen ? (
                  <Col xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <div className="address-format-label">Qty:</div>
                  </Col>
                ) : null}

                {/* Quantity Dropdown */}
                <Col xs={isExtraSmallScreen ? 6 : hideDeleteButton ? 4 : 3} style={{ paddingLeft: 0 }}>
                  <Select
                    styles={{
                      menu: menuStyles,
                      singleValue: valueContainerStyles,
                      control: qtySelectStyles,
                      paddingTop: '5px',
                      container: qtyContainerStyles
                    }}
                    options={qtyOptions}
                    onChange={option => handleChangeMultiAddressQty(option, keyNum)}
                    value={qtyValue}
                    // disable qty unless an address is selected
                    isDisabled={!addressSelections[keyNum] || _.isEmpty(addressSelections[keyNum].address)}
                  />
                </Col>

                {/* X Button  */}
                {/* Hide if no selections made yet */}
                {hideDeleteButton ? null : (
                  <Col
                    xs={isExtraSmallScreen ? 3 : 1}
                    style={{
                      display: 'flex',
                      justifyContent: keyNum === 0 ? 'space-around' : 'center',
                      flexDirection: keyNum === 0 ? 'column' : 'inherit',
                      alignItems: 'center',
                      paddingLeft: 0
                    }}
                  >
                    {/* Render an X unless on small screen */}
                    {isExtraSmallScreen ? (
                      <a
                        style={{ cursor: 'pointer', color: 'var(--mf-secondary-color)' }}
                        onClick={() => deleteSelection(keyNum)}
                      >
                        Remove
                      </a>
                    ) : (
                      <X
                        style={{ cursor: 'pointer', color: 'var(--mf-secondary-color)' }}
                        onClick={() => deleteSelection(keyNum)}
                      />
                    )}
                  </Col>
                )}
              </Row>
            )
          })
        }

        {/* Split Address button */}
        {!showNewSelection &&
        addressSelections[finalKeyNum].quantity &&
        addressSelections[finalKeyNum].quantity.value &&
        !_.isEmpty(addressSelections[finalKeyNum].address) &&
        !mayNotBeSplit ? (
          <Row>
            <Col xs={12} style={{ paddingBottom: '10px' }}>
              <Button
                color="secondary"
                style={{ width: '100%' }}
                onClick={handleShowNewSelection}
                disabled={_.isEmpty(selectedCustomerAddress)}
              >
                Split Quantity to New Address
              </Button>
            </Col>
            <Col xs={12}>
              <p style={{ float: 'right' }}>
                {_.isEmpty(selectedCustomerAddress) ? (
                  <>
                    <a onClick={() => window.scrollTo(0, 0)}>
                      Please Select Default Shipping Address Above to Enable Multi-Address Shipping
                    </a>
                    <br />
                  </>
                ) : (
                  <>
                    {itemsAssignedToCustomAddresses} of {cartItem.quantity}
                    {itemsAssignedToCustomAddresses === cartItem.quantity ? ' assigned to addresses' : null}
                  </>
                )}

                {/* Error messages */}
                {itemsAssignedToCustomAddresses < cartItem.quantity ||
                (Object.values(addressSelections).length === 1 &&
                  !(addressSelections[0].quantity && addressSelections[0].quantity.value === cartItem.quantity)) ? (
                  <>
                    {' '}
                    -{' '}
                    <span style={{ color: 'red' }}>
                      Add <strong>{cartItem.quantity - itemsAssignedToCustomAddresses}</strong> to addresses
                    </span>
                  </>
                ) : null}
                {itemsAssignedToCustomAddresses > cartItem.quantity ? (
                  <>
                    {' '}
                    -{' '}
                    <span style={{ color: 'red' }}>
                      Remove <strong>{itemsAssignedToCustomAddresses - cartItem.quantity}</strong> from addresses
                    </span>
                  </>
                ) : null}
              </p>
            </Col>
          </Row>
        ) : null}

        {mayNotBeSplit ? (
          <p style={{ fontSize: '0.8em' }}>
            <em>
              Product can not be split further due to not enough qty in cart{qtyInSetText ? ` (${qtyInSetText})` : ''}
            </em>
          </p>
        ) : null}

        {/* Clear Selection button - only show if a selection was made for this item */}
        {Object.keys(addressSelections).length === 1 || cartItem.digitalProofing ? null : (
          <Row>
            <Col
              style={{
                fontWeight: 'lighter',
                fontSize: '0.8em',
                textAlign: 'right',
                float: 'right',
                cursor: 'pointer'
              }}
            >
              {/*  */}
              <Button onClick={clearSelections} color="secondary" size="sm" outline style={{ border: 'none' }}>
                clear all address selections
              </Button>
            </Col>
          </Row>
        )}

        {/* Address Form Modal */}
        <Modal
          isOpen={showAddressForm !== false}
          toggle={() => setShowAddressForm(false)}
          className="new-address-modal"
        >
          <ModalBody>
            <NewAddressForm
              afterCreateAddress={afterCreate}
              newAddressMode={showAddressForm !== false}
              setNewAddressMode={setShowAddressForm}
            />
          </ModalBody>
        </Modal>
      </>
    )
}

const mapStateToProps = (state, ownProps) => {
  return {
    multiAddressSelection: state.checkout.multiAddressSelection.filter(
      sel => sel.cartItemId === ownProps.cartItem.cart_item_id
    ),
    customer_addresses: state.customer_addresses,
    managed_customer_addresses: state.managed_customer_addresses,
    portal: state.portal,
    selectedCustomerAddress: state.checkout.selectedCustomerAddress,
    customerGroup: state.customerGroup,
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps, {
  setMultiAddressSelection,
  clearMultiAddressSelection,
  setErrors
})(MultiAddressSelection)
