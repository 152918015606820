import { CART_MULTIPLE_MANAGED_CUSTOMER_ADDRESSES } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case CART_MULTIPLE_MANAGED_CUSTOMER_ADDRESSES:
      return action.payload
    default:
      return state
  }
}
