import _ from 'lodash'
import { addPriceDataToBundleProducts } from '../../helpers/cartItemsFormatter'
import { resolvePrice } from '../Checkout/checkoutFunctions'

// Outputs priceData object: { unit_price: 000, subtotal: 000 }
// Note: input product should have associated template data
// Only product and quantity are required
// for BB, pass in the parent bundle quantity for accurate calculation
export const calculateFinalProductPrice = (
  product,
  quantity,
  configSelections = {},
  templateFieldsWithValues = {},
  allCartItems = [],
  portal = null, // for co-op
  location = null, // for co-op
  parentBundleQuantity = 1,
  forProductPage = false,
  productPageQuantity = 1,
  forCartItems = false
) => {
  if (!(allCartItems instanceof Array)) {
    throw `allCartItems must be an array of cart items. Received: ${allCartItems}`
  }

  // initialize variables
  let result = {}
  const setupCharge = product.setup_charge ? product.setup_charge : 0
  let baseUnitPrice = product.price ? product.price : 0
  // bundle product's price is stored in product_price_cents
  baseUnitPrice = product.product_price_cents ? product.product_price_cents : baseUnitPrice
  parentBundleQuantity = parentBundleQuantity || 1
  forProductPage = window.location.pathname.includes('product')
  quantity = quantity || 1

  // if product already has priceData, include previous
  if (product.priceData || forProductPage === true) {
    result = {
      ...product.priceData,
      unit_price: baseUnitPrice,
      subtotal: baseUnitPrice * quantity,
      setup_charge: setupCharge
    }
  } else {
    result = {
      unit_price: baseUnitPrice,
      subtotal: baseUnitPrice * quantity,
      setup_charge: setupCharge
    }
  }

  // ** BREAKABLE BUNDLE PRICE **

  // if product is a breakable bundle, we need to calculate price for each child product
  if (product.flags?.is_bundle && product.flags.bundle_type === 'Breakable') {
    // add price data to bundle products if on product page
    if (forProductPage) {
      // reset result if BB; price will be derived from child products
      result = {}
      const bundleProductsWithPriceData = addPriceDataToBundleProducts(
        product.bundle.products,
        portal,
        location,
        allCartItems,
        productPageQuantity,
        configSelections,
        templateFieldsWithValues,
        forCartItems
      )
      product.bundle.products = bundleProductsWithPriceData
    }
    // Then resolve price for BB
    result = resolveBreakablePriceData(product)
    result.subtotal = result.unit_price * quantity + result.setup_charge
  }

  // ** TIER PRICING **
  // if this product has tier pricing,
  // aggregate other products in cart that have the same product ID.
  // if the combined quantity reaches a tier,
  // adjust price accordingly
  if (
    product.flags?.has_tier_price || // TP check for non-bundle products
    product.has_tier_price ||
    (product.tier_pricing && product.tier_pricing.length > 0) // for bundle prods
  ) {
    const totalInCart = totalCountProductInCart(product, allCartItems)
    // if on product page, we need to know the total number to consider for tier pricing,
    // which is the bundle product's quantity x the quantity the user has requested for parent bundle
    const totalOnProductPage = forCartItems === true ? 0 : quantity * parentBundleQuantity

    const aggregateProductTotal = totalInCart + totalOnProductPage

    const matchedTierPrice = resolveTierPrice(
      product.tier_prices ? product.tier_prices : product.tier_pricing, // one for bundle products
      aggregateProductTotal
    )

    // If we found a qualifying tier price, use its unit price; otherwise, priceData remains unchanged
    // tier price modifies subtotal and unit price (replaces retail pricing)
    if (matchedTierPrice) {
      result = {
        ...result,
        original_unit_price: result.unit_price,
        subtotal: matchedTierPrice.unit_price_cents * quantity,
        unit_price: matchedTierPrice.unit_price_cents,
        tier_price_applied: true,
        tier_price_unit_price: matchedTierPrice.unit_price_cents,
        tier_price_subtotal: matchedTierPrice.unit_price_cents * quantity,
        tierPriceQty: matchedTierPrice.quantity ? matchedTierPrice.quantity : matchedTierPrice.product_qty,
        tierPriceText: matchedTierPrice.price_per_qty_text
      }
    } else {
      delete result.tier_price_applied
      delete result.tier_price_unit_price
      delete result.tier_price_subtotal
      delete result.tierPriceQty
      delete result.tierPriceText
    }
  }

  // ** ON SALE PRICING **
  // sale price modifies subtotal and unit price as it discounts the current retail price
  if (productIsOnSale(product)) {
    result = {
      ...result,
      sale_price_applied: true,
      sale_price_unit_price: product.sale_price,
      sale_price_subtotal: product.sale_price * quantity,
      // use sale price if it is lower than current price
      unit_price: product.sale_price < result.unit_price ? product.sale_price : result.unit_price,
      subtotal: product.sale_price * quantity < result.subtotal ? product.sale_price * quantity : result.subtotal
    }
  }

  // ** CONFIGURABLE ADDITIONAL PRICE **
  const isProductConfigurable =
    (product.flags && product.flags.is_configurable) || product.is_configurable || product.is_configured

  if (isProductConfigurable) {
    let thisProductConfigSelections
    if (product.flags?.is_configurable && product.flags.is_configured && !forCartItems) {
      thisProductConfigSelections = product.variants
    } else if (product.flags?.is_configurable) {
      // on a cart_item, config selections are stored in the product itself not a separate object
      if (forCartItems) {
        thisProductConfigSelections = product.variants.map(v => v.nested_data[0])
      } else {
        thisProductConfigSelections = Object.values(configSelections)
      }
    } else if (product.is_configurable) {
      // indicates bundle product
      // may have to get selected options for this product
      if (forCartItems) {
        thisProductConfigSelections = product.variants.map(v => v.nested_data[0])
      } else if (!configSelections.selected_options) {
        thisProductConfigSelections = configSelections[product.bundle_group_product_id]
          ? Object.values(configSelections[product.bundle_group_product_id].selected_options)
          : []
      } else {
        thisProductConfigSelections = configSelections.selected_options
          ? Object.values(configSelections.selected_options)
          : []
      }

      if (thisProductConfigSelections.length === 0) {
        thisProductConfigSelections = product.nested_data.filter(
          el => !!el.configurable_option_id || !!el.configurable_variant_id
        )
      }
    } else if (product.flags?.is_configured && product.variants) {
      // this doesn't necessarily mean configured - simple products have this flag too
      thisProductConfigSelections = product.variants.filter(el => !!el.configurable_variant_id)
    }
    const thisProductAdditionalUnitPrice = calculateConfigurableAdditionalPrice(thisProductConfigSelections)
    if (thisProductAdditionalUnitPrice > 0) {
      result = {
        ...result,
        configurable_additional_price_applied: true,
        configurable_additional_price_per_unit: thisProductAdditionalUnitPrice,
        configurable_additional_price_subtotal: thisProductAdditionalUnitPrice * quantity
      }
    } else {
      result = {
        ...result,
        configurable_additional_price_applied: false,
        configurable_additional_price_per_unit: 0,
        configurable_additional_price_subtotal: 0
      }
    }
  }

  // ** TEMPLATE ADDITIONAL PRICE **
  const productHasTemplate =
    !!((product.flags && product.flags.has_template) || product.has_template) && !!product.template
  if (productHasTemplate) {
    const extraPrices = { totalTemplateShippingFee: 0, totalTemplateAdditionalPrice: 0 }

    // if product is inside a bundle:
    if (product.has_template) {
      if (
        templateFieldsWithValues &&
        // if it's either an obj or array
        templateFieldsWithValues[product.bundle_group_product_id]
      ) {
        templateFieldsWithValues = Object.values(templateFieldsWithValues[product.bundle_group_product_id])
      } else {
        templateFieldsWithValues = product.template
      }
    } else {
      templateFieldsWithValues = Object.values(templateFieldsWithValues)
    }
    // only fields with options will have additional pricing. grab only those
    // input can have additional price but only if there is an additional option with price added to it
    const inputTypeWithAdditionalPrice = ['multiselect', 'checkbox', 'dropdown', 'radio', 'image_selection', 'input']
    const templateFields = templateFieldsWithValues.filter(el => inputTypeWithAdditionalPrice.includes(el.input_type))

    templateFields.forEach(field => {
      // if value isn't present or is an empty object
      if (!field.value || (typeof field.value === 'object' && Object.keys(field.value).length === 0)) {
        return
      }
      let selectedOption

      if (field.input_type === 'input') {
        // input will only have one option and additional price on first option
        if (field.nested_data.length > 0 && !!field.nested_data[0].price) {
          selectedOption = field.nested_data[0]
          calculateTemplateAdditionalPrice(selectedOption, 1, extraPrices)
        }
      } else {
        let fieldValue = { ...field.value }

        if (typeof field.value === 'string') {
          fieldValue = JSON.parse(field.value)
        }

        // for dropdowns or image selection, convert to object, unless it already is one
        if (field.input_type === 'dropdown' || field.input_type === 'image_selection') {
          fieldValue = typeof fieldValue !== 'object' ? fieldValue : field.value
          fieldValue = { [fieldValue]: true }
        }

        // for each value that is true, (if a dropdown/image selection has an option selected, it will be the only one)
        const optionIds = Object.keys(fieldValue).map(el => parseInt(el, 10))

        optionIds.forEach(optionId => {
          if (
            field.input_type === 'dropdown' ||
            field.input_type === 'image_selection' ||
            fieldValue[optionId] === true ||
            fieldValue[optionId][optionId] === true
          ) {
            // find that option
            // selectedOption = field.template_field_options.find(e => e.template_field_option_id === optionId)
            selectedOption = field.template_field_options.find(e => e.id === optionId)

            // determine quantity of product
            // qtyBoxNum is only valid for checkbox
            const quantity =
              field.input_type === 'checkbox' && fieldValue[optionId]['qtyBoxNum']
                ? parseInt(fieldValue[optionId]['qtyBoxNum'])
                : selectedOption && selectedOption.quantity
                  ? parseInt(selectedOption.quantity)
                  : 1

            // if there is a selection and it has a price > 0
            if (selectedOption && selectedOption.price) {
              calculateTemplateAdditionalPrice(selectedOption, quantity, extraPrices)
            }
          }
        })
      }
    })
    // Add template additional price to totals
    result.template_additional_price_applied = true
    result.template_total_template_additional_unit_price =
      extraPrices.totalTemplateAdditionalPrice + extraPrices.totalTemplateShippingFee
    result.template_additional_unit_price = extraPrices.totalTemplateAdditionalPrice
    result.template_additional_shipping = extraPrices.totalTemplateShippingFee
    result.template_additional_price_subtotal =
      (extraPrices.totalTemplateAdditionalPrice + extraPrices.totalTemplateShippingFee) * quantity
  }

  // ** COOP PRICE **
  if (coopEnabledOnProductPortalAndLocation(product, portal, location)) {
    let coopPercent = portal.coop_percentage_100
    if (location.coop_overwrite === true) {
      coopPercent = location.coop_percentage_100
    }
    const totalUnitPrice =
      result.unit_price +
      (result.template_total_template_additional_unit_price
        ? result.template_total_template_additional_unit_price
        : 0) +
      (result.configurable_additional_price_per_unit ? result.configurable_additional_price_per_unit : 0)
    const coop_deduction_cents = coopDeductionCents(totalUnitPrice, coopPercent)
    const coopUnitPrice = totalUnitPrice - coop_deduction_cents
    result = {
      ...result,
      coop_enabled: true,
      coop_unit_price: coopUnitPrice,
      coop_subtotal: coopUnitPrice * quantity,
      coop_deduction_cents
    }
  }
  if (product.bundle_price_diff) {
    result.unit_price += product.bundle_price_diff
  }

  if (product.product_extra_price_cents) {
    result.extra_price_cents = product.product_extra_price_cents
    result.extra_price_cents_subtotal = product.product_extra_price_cents * quantity
  }

  // add setup charge. setup charge is one time charge per cart item
  if (product.setup_charge) {
    result.setup_charge = product.setup_charge
  }

  return result
}

const resolveTierPrice = (tierPrices, totalQty = 0) => {
  return validTierPrice(tierPrices, totalQty)
}

// returns pricedata for given breakable bundle based on child products
export const resolveBreakablePriceData = breakableBundle => {
  const sumOfAllSubtotals = breakableBundle.bundle.products.reduce(
    (memo, prod) => resolvePrice(prod.priceData, false, false, true).result + memo,
    0
  )
  const sumOfAllSetupCharges = breakableBundle.bundle.products.reduce(
    (memo, prod) => resolvePrice(prod.priceData, false, false, true).setupCharge + memo,
    0
  )

  const priceData = {
    unit_price: sumOfAllSubtotals,
    setup_charge: sumOfAllSetupCharges
  }

  // if bundle and all items have coop, include coop totals
  return priceData
}

const withinSalePriceDateRange = product => {
  const currDate = new Date()
  const currTimestamp = currDate.getTime()
  return (
    new Date(product.sale_price_from_date_and_time).getTime() < currTimestamp &&
    new Date(product.sale_price_to_date_and_time).getTime() > currTimestamp
  )
}

export const coopEnabledOnProductPortalAndLocation = (product, portal, location) => {
  if (!portal || !location) {
    return false
  } else {
    return product.coop_enabled && portal.coop_enabled && location.coop_enable
  }
}

export const productIsOnSale = product => product.sale_price > 0 && withinSalePriceDateRange(product)
export const coopDeductionCents = (price, coopPercent) => price * (coopPercent / 100)

export const calculateConfigurableAdditionalPrice = productSelections => {
  if (productSelections) {
    return productSelections.reduce((memo, variantOption) => {
      return memo + resolveExtraPriceFromOption(variantOption)
    }, 0)
  } else {
    return 0
  }
}

const resolveExtraPriceFromOption = option => {
  if (option.is_pre_configured) {
    return resolveExtraPriceFromOption(option.nested_data[0])
  } else if (option.price_modifier && (option.has_price_modifier === true || option.configurable_has_price_modifier)) {
    return option.price_modifier.price_modifier_cents
  } else {
    return 0
  }
}

// accepts tier prices array and qty to check
// returns applicable tier price object
const validTierPrice = (tierPrices, qty) => {
  // first, sort tier prices by product_qty descending
  tierPrices = _.cloneDeep(tierPrices)
  const sortedTp = _.orderBy(tierPrices, 'product_qty', 'desc')
  // find the first (highest) tier price for which we have sufficient qty
  return sortedTp.find(tp => qty >= tp.product_qty)
}

// returns total number of products in the given cart for given product ID
const totalCountProductInCart = (product, cartItems) => {
  const productId = product.product_id

  // reduce each cart item to a total quantity of the product in cart
  return (
    cartItems &&
    cartItems.reduce((memo, cartItem) => {
      // if product is a breakable bundle
      if (cartItem.flags?.bundle_type === 'Breakable') {
        // we must further reduce quantities for matching products inside the bundle
        return (
          cartItem.bundle.products.reduce((memo2, bundleProduct) => {
            if (bundleProduct.product_id === productId) {
              const parentBundleQuantity = cartItem.quantity
              return (bundleProduct.product_quantity + memo2) * parentBundleQuantity
            } else {
              return memo2
            }
          }, 0) + memo
        )
      } else {
        // for non bundle products simply return the quantity if product matches
        return cartItem.product_id === productId ? cartItem.quantity + memo : memo
      }
    }, 0)
  )
}

// calculate addition prices for options on templates
const calculateTemplateAdditionalPrice = (selectedOption, quantity, extraPrices) => {
  const optionAdditionalPrice = parseFloat(selectedOption.price * quantity)
  if (selectedOption && optionAdditionalPrice > 0) {
    extraPrices.totalTemplateAdditionalPrice += optionAdditionalPrice // * selectedOption.quantity
  }
  // (or a shipping fee > 0),
  if (selectedOption && selectedOption.shipping_fee && parseFloat(selectedOption.shipping_fee) > 0) {
    const optionExtraShippingFee = parseFloat(selectedOption.shipping_fee)

    // additional check here to see if shipping fee is applied to each individual item, or a flat fee
    extraPrices.totalTemplateShippingFee +=
      optionExtraShippingFee * (selectedOption.shipping_fee_quantity ? quantity : 1)
  }
}

// REDUX ACTIONS
export const mapDispatchToProps = dispatch => {
  return {
    setTemplateFieldValue: data => dispatch({ type: 'SET_TEMPLATE_FIELD_VALUE', payload: data }),
    updateConfigSelection: data => dispatch({ type: 'UPDATE_CONFIG_SELECTION', payload: data }),
    updateArtworkSelection: data => dispatch({ type: 'UPDATE_ARTWORK_SELECTION', payload: data }),
    updateBundleTemplateSelection: data => dispatch({ type: 'UPDATE_BUNDLE_TEMPLATE_FIELD', payload: data })
    // updateCreditBalances: data => dispatch({ type: 'UPDATE_CREDIT_CARD', payload: data }),
  }
}
// had to create these slightly differently to integrate with other functions
// inside their parent component's mapDispatchToProps
export const updateDigitalProofingSelection = data => async dispatch =>
  dispatch({ type: 'UPDATE_DIGITAL_PROOFING_SELECTION', payload: data })
export const setErrors = data => async dispatch => dispatch({ type: 'SET_PRODUCT_PAGE_ERRORS', payload: data })
export const clearProductPage = data => async dispatch => {
  dispatch({ type: 'CLEAR_PRODUCT' })
}
export const addFullSkuToBundleProduct = data => async dispatch =>
  dispatch({ type: 'ADD_SKU_TO_BUNDLE_PRODUCT', payload: data })
export const updateProduct = (data, cartItems) => async (dispatch, getState) =>
  dispatch({ type: 'UPDATE_PRODUCT_DETAILS', payload: { data, cartItems } })
// assign a product to a bundle (for bundle groups)
export const setBundleProduct = (bundleGroup, product) => async dispatch =>
  dispatch({ type: 'ASSIGN_BUNDLE_PRODUCT', payload: { bundleGroup, product } })

export const calculateBundleSelectionsAdditionalPrice = productSelections => {
  return productSelections.reduce((memo, bundleProduct) => {
    // calculate the additional price by reducing price_modifier_cents of all options
    // for each of every product's selected options,
    const thisProductAdditionalPrice = Object.values(bundleProduct.selected_options).reduce((memo, option) => {
      if (option && option.has_price_modifier === true) {
        return memo + option.price_modifier.price_modifier_cents
      } else {
        return memo
      }
    }, 0)
    return thisProductAdditionalPrice + memo
  }, 0)
}

export const validateTemplateField = field => {
  if (!field.is_required) return true

  switch (field.input_type) {
    // radio must have one option selected
    case 'radio':
    case 'multiselect':
    case 'checkbox':
      if (Object.keys(field.value).length > 0 && Object.keys(field.value).some(key => field.value[key][key] === true)) {
        return true
      } else {
        return false
      }
    default:
      if (!field.value || (typeof field.value === 'object' && Object.keys(field.value).length === 0)) {
        return false
      } else {
        return true
      }
  }
}

// Display requires approval is product requires approval and all products dont require approval
export const productRequiresApproval = (product, location) =>
  location.requires_approval && (location.all_products_require_approval ? false : product.requires_approval)

// Check quantity for product and check if stock allows
export const productStockError = (products, quantity, stocks) => {
  const uniqueProductSkuIds = [...new Set(products.map(prod => prod.product_sku_id))]
  // if we don't have stock yet, return true
  if (Object.values(stocks).length < uniqueProductSkuIds.length) {
    return true
  }
  // if any product has insufficient stock, return true
  return combineProductsForStockCheck(products).some(product => {
    // Configs nested as product_full_sku
    const productStock = stocks[product.product_full_sku] || stocks[product.product_sku]
    // BB Bundle has quantity per product nested as product_quantity
    const productQuantity = (product.product_quantity || 1) * quantity
    return !Number.isInteger(productStock) || productStock <= 0 || productQuantity > productStock
  })
}

// Combine products/configurables for stock check
const combineProductsForStockCheck = products => {
  const productMap = {}

  products.forEach(product => {
    const sku = product.product_full_sku || product.product_sku
    const key = `${sku}_${product.product_id}`

    if (productMap[key]) {
      productMap[key].product_quantity += product.product_quantity || 1
    } else {
      productMap[key] = {
        product_id: product.product_id,
        bundle_group_product_id: product.bundle_group_product_id,
        product_name: product.product_name,
        product_full_sku: product.product_full_sku,
        product_sku: product.product_sku,
        product_quantity: product.product_quantity
      }
    }
  })

  return Object.values(productMap)
}
