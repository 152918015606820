import _ from 'lodash'
import { toast } from 'react-toastify'
import { Container, Row, Col, Button } from 'reactstrap'
import branditRails from '../api/brandit_rails'
import { STORE_APP_VERSION } from '../constants'
import { applyCoopCreditsToCartItems, formatCartItems } from '../helpers/cartItemsFormatter'
import history from '../history'
import { checkStoreVersion, checkApiVersion } from '../hooks/useVersionCheck'
import { store } from '../store'
import { formatAddress, legacyLogOut, mergeAddressData } from '../util/utils'
import { logOut, setSessionToken } from './sessions'
import {
  FETCH_BUNDLE,
  FETCH_PRODUCTS,
  CLEAR_ERRORS,
  CREATE_SESSION_ERROR,
  CREATE_SUCCESS_MESSAGE,
  CREATE_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_ADDRESSES,
  FETCH_PRODUCT_DETAILS_SKU,
  FETCH_MANAGED_CUSTOMER_ADDRESSES,
  CLEAR_MANAGED_CUSTOMER_ADDRESSES_ERROR,
  UPDATE_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS,
  FETCH_PORTAL_CATEGORY_PRODUCTS,
  FETCH_PORTAL_CATEGORIES,
  FETCH_CLIENT_ORDERS,
  UPDATE_ORDER,
  FETCH_SHIPPING_QUOTE_ERROR,
  CREATE_CODE_ERROR,
  CLEAR_SUCCESS,
  CLEAR_CODE_ERRORS,
  TAX_ERROR,
  CLEAR_TAX_ERROR,
  FETCH_ORDER_DETAILS,
  FETCH_PRODUCT_BUNDLE_SELECTIONS,
  PROOFING_PREVIEW,
  FETCH_PRODUCT_SUGGESTIONS,
  SAVE_SEARCH_QUERY,
  SAVE_PRODUCT_SUGGESTIONS,
  FETCH_ORDER_INVOICE,
  VERIFY_PASSWORD_TOKEN_SUCCESS,
  VERIFY_PASSWORD_TOKEN_FAILURE,
  PRODUCT_ERROR,
  INVENTORY_LOADING,
  FETCH_INVENTORY
} from './types'

const isSessionRequest = url => {
  return (
    url.includes('sessions') ||
    url.includes('/change_password') ||
    url.includes('/reset_password') ||
    url.includes('/portal_core/user_update') ||
    url.includes('/auth0_callback')
  )
}

const pushToLogInAndReload = () => {
  // These will be removed in future
  const isRedirectLink = JSON.parse(localStorage.getItem('login_redirect_link_selected'))
  const redirectLogIn = isRedirectLink
    ? localStorage.getItem('login_redirect_link')
    : localStorage.getItem('custom_login_url') || '/log-in'
  //////////////
  const query = new URLSearchParams(location.search)
  const redirect = query.get('redirect_to')

  // first make sure user is totally logged out
  legacyLogOut()

  if (redirect) {
    // will redirect back if there is redirect_to url param
    history.push(location.href)
  } else if (redirectLogIn) {
    history.push(redirectLogIn)
  } else {
    history.push('/log-in')
  }
  history.go(0)
}

const isSessionPath = urlPath => {
  const paths = ['log-in', localStorage.getItem('custom_login_url'), 'sso-log-in']
  return paths.some(sessionPath => {
    return urlPath.includes(sessionPath)
  })
}

branditRails.interceptors.response.use(
  response => {
    const apiVersion = response.headers['x-api-version']

    // Check API version and store version for new deployments
    checkApiVersion(apiVersion)
    checkStoreVersion()

    return response
  },
  error => {
    let errorMessage = ''
    // This will capture all errors from API. Use this for global response error handling.
    switch (error?.response?.status) {
      case 401:
        const responseURL = error.response?.request?.responseURL
        // don't redirect on log-in because that API has its own error handling
        const preventRedirect =
          (responseURL && isSessionRequest(responseURL)) || isSessionPath(window.location.pathname)

        // ensure store.dispatch(logOut()) runs whenever /current_user returns unauthorized
        const isCurrentUserRequest = responseURL.includes('/current_user')

        if (preventRedirect && !isCurrentUserRequest) {
          return Promise.reject(error)
          // break
        } else {
          // Redirect to log-in only if token is expired and portal is private
          // toast.info('Session expired, please log in. Redirecting...', { toastId: 'loginRedirect' })
          // setTimeout(() => {
          //   pushToLogInAndReload()
          // }, 3000)
        }
        // regardless of redirect, remove invalid token from storage

        store.dispatch(logOut())
        break
      case 403:
        errorMessage = { message: 'Your account is not yet approved' }
        return Promise.reject(error)
      case 400:
        errorMessage = { message: 'Bad request' }
        return Promise.reject(error)
      case 422:
        toast.error(error.response.data)
      default:
        return Promise.reject(error)
    }
  }
)

branditRails.interceptors.request.use(config => {
  config.headers['guest_user_id'] = localStorage.getItem('guest_user_id') ? localStorage.getItem('guest_user_id') : ''
  config.headers['X-Location-ID'] = localStorage.getItem('currentlocationId')
    ? localStorage.getItem('currentlocationId')
    : ''
  config.headers['X-Auth-Token'] = localStorage.getItem('authToken') ? localStorage.getItem('authToken') : ''
  // config.headers['X-User-Email']  = localStorage.email
  // config.headers['X-User-ID']     = localStorage.user_id
  // add version to headers
  config.headers['X-Store-App-Version'] = STORE_APP_VERSION
  return config
})

export const fetchPages = () => async dispatch => {
  const response = await branditRails.get('/site_pages')
  dispatch({ type: 'FETCH_PAGES', payload: response.data })
  return response.data
}

export const fetchPortal = () => async dispatch => {
  const response = await branditRails.get('/site_portal')
  const portalData = response?.data
  if (portalData) {
    setPortalDataInStorage(portalData)
  }
  dispatch({ type: 'FETCH_CUSTOMER_GROUP', payload: portalData?.no_user_customer_group })
  dispatch({ type: 'FETCH_PORTAL', payload: portalData })
  return portalData
}

const setPortalDataInStorage = portalData => {
  localStorage.setItem('coop_def', portalData.context_dictionary?.coop_def)
  localStorage.setItem('ecaid_def', portalData.context_dictionary?.ecaid_def)
  localStorage.setItem('eclid_def', portalData.context_dictionary?.eclid_def)
  localStorage.setItem('ecpid_def', portalData.context_dictionary?.ecpid_def)
  localStorage.setItem('expiry_def', portalData.context_dictionary?.expiry_def)
  localStorage.setItem('location_def', portalData.context_dictionary?.location_def)
  localStorage.setItem('shop_def', portalData.context_dictionary?.shop_def)
  localStorage.setItem('account_credit_def', portalData.context_dictionary?.account_credit_def)
  localStorage.setItem('login_redirect_link_selected', portalData.login_redirect_link_selected)
  localStorage.setItem('login_redirect_link', portalData.login_redirect_link)
  localStorage.setItem('custom_login_url', portalData.custom_login_url)
}

export const fetchPortalPage = page_id => async dispatch => {
  const response = await branditRails.get(`/portal_page/${page_id}`)
  dispatch({ type: 'FETCH_PORTAL_PAGE', payload: response.data })
}

const _enqueuedInvProductIds = []
/**
 * fetchInventory - Fetches inventory for the given product id
 *
 * @param {string} productId - The product id
 * @returns {Function} - A thunk function that dispatches actions
 */
export const fetchInventory = productId => async dispatch => {
  dispatch({ type: INVENTORY_LOADING })
  if (_enqueuedInvProductIds.includes(productId)) {
    return
  }
  _enqueuedInvProductIds.push(productId)
  const response = await branditRails.get(`/inventory/${productId}`)
  dispatch({ type: FETCH_INVENTORY, payload: { data: response.data, productId } })
  _enqueuedInvProductIds.splice(_enqueuedInvProductIds.indexOf(productId), 1)
}

export const fetchProducts =
  (page = null) =>
  async dispatch => {
    let paginationParams = ''
    if (page) {
      paginationParams = `?page=${page}`
      const response = await branditRails.get(`/site_products${paginationParams}`)
      dispatch({ type: 'FETCH_PAGINATED_PRODUCTS', payload: response.data })
    } else {
      const response = await branditRails.get(`/site_products${paginationParams}`)
      dispatch({ type: FETCH_PRODUCTS, payload: response.data })
    }
  }

export const fetchPortalCategoryProducts =
  (categories, locationId, tagFilters, page = null, searchQuery) =>
  async dispatch => {
    let paginationParams = ''
    let searchQueryParams = ''
    let categoryParams = ''
    let tagFiltersParams = ''

    if (searchQuery) {
      searchQueryParams = `&search=${searchQuery}`
    }
    if (tagFilters) {
      tagFiltersParams = `&tag_filters=${JSON.stringify(tagFilters)}`
    }
    if (page) {
      paginationParams = `&page=${page}`
      if (categories && categories.length !== 0) {
        categoryParams = `&categories=${categories}`
      }
      const response = await branditRails.post(
        `/site_products_for_categories?location_id=${locationId}${categoryParams}${paginationParams}${searchQueryParams}${tagFiltersParams}`
      )
      if (response && response.data) {
        dispatch({ type: 'FETCH_PAGINATED_PRODUCTS', payload: response.data })
      }
    } else {
      const response = await branditRails.post(
        `/site_products_for_categories?&location_id=${locationId}${searchQueryParams}${tagFiltersParams}`
      )
      if (response && response.data) {
        dispatch({ type: FETCH_PORTAL_CATEGORY_PRODUCTS, payload: response.data })
      }
    }
  }

export const fetchLocationProducts =
  (tagFilters, page = null, searchQuery) =>
  async dispatch => {
    let paginationParams = ''
    let searchQueryParams = ''
    let tagFilterParams = ''

    if (searchQuery) {
      searchQuery = searchQuery.replace('&', '')
      searchQueryParams = `&search=${searchQuery}`
    }

    if (tagFilters) {
      tagFilterParams = `&tag_filters=${JSON.stringify(tagFilters)}`
    }

    if (page) {
      paginationParams = `&page=${page}`
      const response = await branditRails.post(
        `/site_products?${paginationParams}${searchQueryParams}${tagFilterParams}`
      )
      dispatch({ type: 'FETCH_PAGINATED_PRODUCTS', payload: response.data })
    } else {
      const response = await branditRails.post(
        `/site_products?${paginationParams}${searchQueryParams}${tagFilterParams}`
      )
      dispatch({ type: FETCH_PRODUCTS, payload: response.data })
    }
  }

export const saveSearchQuery = data => ({
  type: SAVE_SEARCH_QUERY,
  payload: data
})

export const saveProductSuggestions = data => ({
  type: SAVE_PRODUCT_SUGGESTIONS,
  payload: data
})

export const fetchLocations =
  (search = '', page = 0) =>
  async dispatch => {
    const response = await branditRails.get(`/site_locations?search=${search}&page=${page}`)
    if (response && response.data) {
      return response.data
    }
  }

export const fetchPortalCategories = portal_id => async dispatch => {
  const response = await branditRails.get(`/portal_categories?portal_id=${portal_id}`)
  dispatch({ type: FETCH_PORTAL_CATEGORIES, payload: response.data })
}

export const fetchLocationCategories = locationId => async dispatch => {
  const response = await branditRails.get(`/location_categories/${locationId}`)
  dispatch({ type: 'SET_LOCATION_CATEGORIES', payload: response.data })
}

export const fetchBundle = (bundle_id, location_id) => async dispatch => {
  const response = await branditRails.get(`/bundle/${bundle_id}/${location_id}`)
  dispatch({ type: FETCH_BUNDLE, payload: response.data })
}
export const fetchSelectedBundle = (bundle_id, formValues) => async dispatch => {
  const response = await branditRails.post(`/bundle/${bundle_id}`, formValues)
  dispatch({ type: FETCH_BUNDLE, payload: response.data })
}

export const fetchLocationProduct = product_id => async dispatch => {
  dispatch({ type: 'CLEAR_PRODUCT' })
  await branditRails.get(`/site_product/${product_id}`).then(response => {
    dispatch({ type: 'FETCH_PRODUCT_DETAILS', payload: response.data })
  })
}

export const clearProductPage = () => async dispatch => {
  dispatch({ type: 'CLEAR_PRODUCT' })
}

export const fetchPortalProductSku = (product_id, portal_id, sku) => async dispatch => {
  const response = await branditRails.get(`/site_product/${product_id}?portal_id=${portal_id}&product_sku=${sku}`)
  dispatch({ type: FETCH_PRODUCT_DETAILS_SKU, payload: response.data })
}

// export const fetchLocationProductSku = (product_id, location_id, sku) => async dispatch => {
//   const response = await branditRails.get(`/site_product/${product_id}?location_id=${location_id}&product_sku=${sku}`);
//   dispatch({ type: FETCH_PRODUCT_DETAILS_SKU,  payload: response.data})
// };

export const fetchProductNameSuggestions = searchQuery => async dispatch => {
  if (searchQuery === '') {
    dispatch({ type: FETCH_PRODUCT_SUGGESTIONS, payload: [] })
    return
  }
  const response = await branditRails.get(`site_product/get_suggestions/${searchQuery}`).then(function (response) {
    dispatch({ type: FETCH_PRODUCT_SUGGESTIONS, payload: response.data })
  })
}

export const fetchUserCredit = () => async dispatch => {
  try {
    const response = await branditRails.get(`/user_credit`)
    dispatch({ type: 'FETCH_USER_CREDIT', payload: response.data })

    return response
  } catch (error) {
    console.error('Failed to fetch user credit:', error)
    return null
  }
}

// sets current location in Redux and localStorage
export const setCurrentLocation = location => async dispatch => {
  if (!location) {
    console.error('Error: Can not set location if not present')
    return
  }
  dispatch({ type: 'SET_CURRENT_LOCATION', payload: location })
}

export const fetchCurrentLocation = locationId => async dispatch => {
  const response = await branditRails.get(`/site_location/${locationId}`)
  dispatch({ type: 'SET_CURRENT_LOCATION', payload: response.data })
}

export const clearErrors = () => async dispatch => {
  dispatch({ type: CLEAR_ERRORS, payload: '' })
}

export const createUser = formValues => async dispatch => {
  dispatch({ type: CLEAR_ERRORS, payload: '' })
  return await branditRails
    .post('/users', formValues)
    .then(function (response) {
      dispatch({ type: CREATE_SUCCESS_MESSAGE, payload: 'Account Created.' })
      return response
    })
    .catch(function (error) {
      if (error.response.status === 422) {
        dispatch({ type: 'CREATE_USER_ERROR', payload: [error.response.data] })
      } else if (
        error.response.status === 500 &&
        error.response.data &&
        error.response.data.exception &&
        error.response.data.exception.includes('index_users_on_username_and_client_id')
      ) {
        dispatch({
          type: 'CREATE_USER_ERROR',
          payload: { message: 'Username Already In Use. Please Use a Different One' }
        })
      } else {
        console.error(error.response)
      }
      return error
    })
}

export const fetchUser = () => async dispatch => {
  return await branditRails
    .get(`/current_user`)
    .then(response => {
      if (response?.data?.id) {
        dispatch({ type: 'SET_CURRENT_USER', payload: response.data })
        dispatch({ type: 'FETCH_LOCATIONS', payload: response.data.all_user_locations })
        dispatch({ type: 'FETCH_CUSTOMER_GROUP', payload: response.data.customer_group })
      }
      return response
    })
    .catch(error => {
      return error
    })
}

export const verifyPasswordToken = token => {
  return dispatch => {
    branditRails
      .post('/verify_password_token', { authtoken: token })
      .then(response => {
        dispatch({ type: VERIFY_PASSWORD_TOKEN_SUCCESS, payload: response.data })
      })
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch({ type: VERIFY_PASSWORD_TOKEN_FAILURE, payload: error.response.data })
        } else {
          dispatch({
            type: VERIFY_PASSWORD_TOKEN_FAILURE,
            payload: { error: 'Network error or response data is undefined' }
          })
        }
      })
  }
}

export const resetUserPassword = formValues => async dispatch => {
  dispatch({ type: CLEAR_ERRORS, payload: '' })
  await branditRails
    .put('/user_reset_password', formValues)
    .then(function (response) {
      dispatch({ type: CREATE_SUCCESS_MESSAGE, payload: 'Email sent. You will receive a link to reset your password.' })
    })
    .catch(function (error) {
      toast.error(error.response.data.error, {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.DANGER,
        className: 'error-toast',
        autoClose: 3000
      })
    })
}

export const clearSuccessMessage = () => async dispatch => {
  dispatch({ type: 'CLEAR_SUCCESS', payload: [] })
}

export const resetPassword = formValues => async dispatch => {
  dispatch({ type: CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/reset_password', formValues)
    .then(function (response) {
      dispatch({ type: CREATE_SUCCESS_MESSAGE, payload: 'New Password sent. You can login again.' })
    })
    .catch(function (error) {})
}

export const changePassword = formValues => async dispatch => {
  return await branditRails
    .post('/change_password', formValues)
    .then(response => {
      dispatch({ type: CREATE_SUCCESS_MESSAGE, payload: 'New password set successfully.' })
      return response
    })
    .catch(error => {
      if (error.response.data.error_code && error.response.data.error_code === 'cannot_reuse') {
        toast.error('You may not reuse your old password.', {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.DANGER,
          className: 'error-toast',
          autoClose: 3000
        })
        return 'invalid'
      } else if (error.response.data.error_code && error.response.data.error_code === 'incorrect_password') {
        toast.error('Incorrect password. Please try again.', {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.DANGER,
          className: 'error-toast',
          autoClose: 3000
        })
      } else if (error.response.data.error_code && error.response.data.error_code === 'not_found') {
        toast.error('No user found with that e-mail address. Please create an account.', {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.DANGER,
          className: 'error-toast',
          autoClose: 3000
        })
      } else if (error.response.data.error_code && error.response.data.error_code === 'not_expired') {
        toast.error('Your password is not yet expired. Please log in normally.', {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.DANGER,
          className: 'error-toast',
          autoClose: 3000
        })
      }
      return error.response
    })
}

export const resendEmailConfirmation = formValues => async dispatch => {
  dispatch({ type: CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/users/confirmation', formValues)
    .then(function (response) {
      dispatch({ type: CREATE_SUCCESS_MESSAGE, payload: 'New confirmation link sent.' })
      dispatch(
        dispatchSweetAlert({
          type: 'success',
          title: 'Confirmation Email Resent',
          alertMessage: 'If an account exists with the email provided, you will receive the confirmation link shortly.',
          onConfirm: () => history.push('/log-in'),
          confirmBtnCssClass: 'mf-primary-btn alert-width-btn',
          confirmBtnText: 'Back to Sign In',
          showCancel: false
        })
      )
    })
    .catch(function (error) {})
}

export const updateUser = formValues => async dispatch => {
  dispatch({ type: CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch('/user_update', formValues)
    .then(function (response) {
      dispatch({ type: CREATE_SUCCESS_MESSAGE, payload: 'Account Updated.' })
    })
    .catch(function (error) {
      let errorMessage = ''
      switch (error.response.status) {
        case 401:
          errorMessage = { message: 'Password invalid. ' }
          dispatch({ type: CREATE_SESSION_ERROR, payload: errorMessage })
          break
        default:
          return null
      }
      dispatch({ type: 'SET_SESSION_STATUS', payload: 'FAILED' })
    })
}

export const createSession = values => async dispatch => {
  dispatch({ type: CLEAR_ERRORS, payload: '' })
  dispatch({ type: 'SET_SESSION_STATUS', payload: 'LOADING' })
  return await branditRails
    .post('/user_sessions', values)
    .then(response => {
      if (response?.data?.auth_token) {
        dispatch(setSessionToken(response.data.auth_token))
        dispatch({ type: 'SET_SESSION_STATUS', payload: 'SUCCESS' })
        dispatch({ type: 'CLEAR_STATUS' })
      } else {
        legacyLogOut()
        dispatch(deleteSession())
      }
      return response
    })
    .catch(error => {
      let errorMessage = ''
      let responseMsg = ''
      switch (error?.response?.status) {
        case 401:
          if (error.response?.data?.error_code === 'not_active') {
            errorMessage = { message: 'Your account is not active' }
            toast.error(errorMessage.message, {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.DANGER,
              className: 'error-toast',
              autoClose: 3000
            })
          } else if (error.response.data.error_code && error.response.data.error_code === 'auth0_login_failed') {
            errorMessage = { message: 'Auth0 login failed. Please try again.' }
            toast.error(errorMessage.message, {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.DANGER,
              className: 'error-toast',
              autoClose: 3000
            })
          } else if (error.response.data.error_code && error.response.data.error_code === 'account_not_found') {
            errorMessage = { message: 'No account found.' }
            toast.error("We couldn't find an account associated with your email address.", {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.DANGER,
              className: 'error-toast',
              autoClose: 4000
            })
          } else if (error.response.data.error_code && error.response.data.error_code === 'domain_not_whitelisted') {
            errorMessage = { message: 'Email domain not white listed.' }
            toast.error('The domain of the provided email is not allowed for registration.', {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.DANGER,
              className: 'error-toast',
              autoClose: 4000
            })
          } else if (error.response.data.error_code && error.response.data.error_code === 'signup_disabled') {
            errorMessage = {
              message:
                'We couldn’t find an account, for this store, with that email address. Please double-check your entry or try a different email.'
            }
            toast.error(errorMessage.message, {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.DANGER,
              className: 'error-toast',
              autoClose: 3000
            })
          } else if (error.response?.data?.error_code === 'password-expired') {
            errorMessage = { message: 'Your password has expired ' }
            toast.error('Password needs to be reset', {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.DANGER,
              className: 'error-toast',
              autoClose: 3000
            })
            responseMsg = 'password-expired'
          } else if (error.response?.data?.error_code === 'not_confirmed') {
            responseMsg = {
              error_type: 'not_confirmed',
              message: 'Please click the confirmation link in your email.'
            }
          } else if (error.response?.data?.error_code === 'not_found') {
            errorMessage = { message: 'No user found with that e-mail address.' }
            toast.error('No user found with that e-mail address. Please create an account.', {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.DANGER,
              className: 'error-toast',
              autoClose: 4000
            })
            responseMsg = 'redirect'
          } else if (error.response?.data?.error_code === 'portal-not-authorized') {
            errorMessage = {
              message:
                'We couldn’t find an account, for this store, with that email address. Please double-check your entry or try a different email.'
            }
            toast.error(errorMessage.message, {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.DANGER,
              className: 'error-toast',
              autoClose: 3000
            })
            responseMsg = 'invalid'
          } else {
            errorMessage = { message: 'Email or password invalid.' }
            toast.error(errorMessage.message, {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.DANGER,
              className: 'error-toast',
              autoClose: 3000
            })
            responseMsg = 'invalid'
          }
          dispatch({ type: CREATE_SESSION_ERROR, payload: errorMessage })
          break
        case 403:
          errorMessage = { message: 'Your account is not yet approved' }
          toast.error('Your account is not yet approved. ', {
            position: toast.POSITION.TOP_CENTER,
            type: toast.TYPE.DANGER,
            className: 'error-toast',
            autoClose: 3000
          })

          dispatch({ type: CREATE_SESSION_ERROR, payload: errorMessage })
          return 'not-yet-approved'
        case 422:
          toast.error('Invalid username or password')
          break
        case 500:
        default:
          responseMsg = {
            // data: {},
            // status: 500,
            message: 'An error occurred. Please try again. If the problem persists, contact support for assistance.'
          }
          break
      }
      dispatch({ type: 'SET_SESSION_STATUS', payload: 'FAILED' })
      dispatch(setLoading('session', false))
      return responseMsg
    })
}

export const deleteSession = () => async dispatch => {
  await branditRails.delete('/user_sessions').then(function (response) {
    localStorage.setItem('user_token', '')
    localStorage.setItem('email', '')

    localStorage.setItem('currentlocationId', '')

    dispatch({ type: 'CLEAR_CURRENT_USER' })
    dispatch({ type: 'CLEAR_CART' })
    dispatch({ type: 'CLEAR_USER_CREDIT' })
  })
}

export const fetchMaxQuantities =
  ({ productIds, customerGroupId }) =>
  async dispatch => {
    const formData = new FormData()

    formData.append('ids', JSON.stringify(productIds))
    formData.append('customer_group_id', customerGroupId)
    const response = await branditRails.post('/product_quantity_available', formData)
    if (response && !_.isEmpty(response)) {
      dispatch({ type: 'SET_MAX_QUANTITIES', payload: response.data })
    }
  }

export const fetchConfigurableSelection = (id, locationId, selectedOptions) => async dispatch => {
  const response = await branditRails.get(
    `/bundle_products/${id}/selection?location_id=${locationId}&selected_options=${selectedOptions}`
  )
  dispatch({ type: FETCH_PRODUCT_BUNDLE_SELECTIONS, payload: response.data })
}

//
// STOCK CHECK FUNCTIONS
export const fetchStockCount =
  (sku, portal_id, location_id, configOptionIds, productId, bundleGroupProductId = null, productSkuId = null) =>
  async dispatch => {
    dispatch({ type: 'PRODUCT_LOADING' })
    // Check if configOptionIds/productSkuId is null or undefined and use an empty string as a fallback
    const configOptionIdsParam = configOptionIds ? `configurable_option_ids=${configOptionIds}` : ''
    const productSkuIdParam = productSkuId ? `product_sku_id=${productSkuId}` : ''

    // create query params string
    const queryParams = [
      `portal_id=${portal_id}`,
      `location_id=${location_id}`,
      configOptionIdsParam,
      `sku=${sku}`,
      productSkuIdParam
    ]
      .filter(param => param) // remove empty params
      .join('&')

    const response = await branditRails.get(`/stock_check/${productId}?${queryParams}`)

    if (response.data.product_sku_id === 'not-found') {
      dispatch({ type: 'CLEAR_PRODUCT_LOADING' })
      return response
    }
    dispatch({ type: 'FETCH_STOCK_COUNT', payload: response.data.inventory, sku })
    dispatch({
      type: 'ADD_PRODUCT_SKU_ID_TO_PRODUCT',
      payload: response.data.product_sku_id,
      bundleGroupProductId
    })
    dispatch({ type: 'CLEAR_PRODUCT_LOADING' })

    return response
  }
// END STOCK CHECK

// CART FUNCTIONS
export const addItemToCart = formValues => async dispatch => {
  dispatch({ type: 'PRODUCT_LOADING' })
  await branditRails
    .post('/cart', formValues)
    .then(resp => {
      // server will provide guest user ID if user is not logged in and adds first item to their cart
      if (resp.headers?.http_guest_user_id) {
        localStorage.setItem('guest_user_id', resp.headers.http_guest_user_id)
      }
      dispatch({ type: 'CLEAR_PRODUCT_LOADING' })
      dispatch({ type: 'CLEAR_PROOFING_PREVIEW' })
    })
    .catch(err => {
      // clear redux even if there is an error
      console.error(err)
      dispatch({ type: 'CLEAR_PRODUCT_LOADING' })
      dispatch({ type: 'CLEAR_PROOFING_PREVIEW' })
      throw err
    })
}

export const fetchCartItems =
  (userId, portal, location, coopCredits = 0) =>
  async (dispatch, getState) => {
    dispatch({ type: 'SET_CART_LOADING' })
    const guestUserId = localStorage.getItem('guest_user_id')
    if (userId && guestUserId) {
      // remove guest_user_id from local storage once user is logged in
      // localStorage.removeItem('guest_user_id')
    } else if (!userId && guestUserId) {
      userId = guestUserId
    }
    const customerGroup = getState().customerGroup
    const cartItemsResponse = await branditRails
      .get('/cart')
      .then(resp => {
        let cartItems = formatCartItems(resp.data.cart_items, portal, location)
        cartItems = applyCoopCreditsToCartItems(cartItems, coopCredits)

        dispatch({ type: 'SET_CART_ITEMS', payload: cartItems })
        dispatch({ type: 'SET_USER_CART_ID', payload: resp.data.user_cart_id })

        if (resp.headers && resp.headers.http_guest_user_id) {
          localStorage.setItem('guest_user_id', resp.headers.http_guest_user_id)
        }
        const formData = new FormData()
        const productIdsArray = cartItems.map(ci => ci.product_id).filter(n => n)

        formData.append('ids', JSON.stringify(productIdsArray))
        formData.append('customer_group_id', customerGroup && customerGroup.id > 0 ? customerGroup.id : null)
        // fetch max qty for cart items if user is logged in
        if (userId > 0) {
          branditRails.post('/product_quantity_available', formData).then(response => {
            if (response && !_.isEmpty(response)) {
              dispatch({ type: 'SET_MAX_QUANTITIES', payload: response.data })
            }
            dispatch({ type: 'MAX_QUANTITY_LOADING', payload: false })
            dispatch({ type: 'CLEAR_CART_LOADING' })
          })
        } else {
          dispatch({ type: 'MAX_QUANTITY_LOADING', payload: false })
          dispatch({ type: 'CLEAR_CART_LOADING' })
        }

        return cartItems
      })
      .catch(err => {
        dispatch({ type: 'CLEAR_CART_LOADING' })
        throw err
      })

    return cartItemsResponse
  }

export const updateCartItems = newCartItems => async dispatch => {
  dispatch({ type: 'UPDATE_CART_ITEMS', payload: newCartItems })
}

export const clearCartLocally = () => async dispatch => {
  dispatch({ type: 'CLEAR_CART_LOCALLY' })
}

export const deleteCartItem = cartItemId => async dispatch => {
  await branditRails.post(`/cart/delete/${cartItemId}`).then(resp => {
    if (resp && resp.status === 200) {
      dispatch({ type: 'DELETE_CART_ITEM', payload: resp.data })
    } else {
      toast.error('Could not delete item from cart')
    }
  })
}

export const updateCartItem = (cartItemId, qty) => async dispatch => {
  dispatch({ type: 'CART_ITEM_LOADING', payload: cartItemId })
  await branditRails
    .patch(`/cart/update/${cartItemId}/${qty}`)
    .then(resp => {
      if (resp && resp.status === 200) {
        dispatch({ type: 'UPDATE_CART_ITEM', payload: resp.data })
      } else {
        toast.error('Could not update item')
      }
      dispatch({ type: 'CLEAR_CART_ITEM_LOADING', payload: cartItemId })
    })
    .catch(err => {
      toast.error('Error: Could not update item')
      dispatch({ type: 'CLEAR_CART_ITEM_LOADING', payload: cartItemId })
    })
}

export const checkStockCartItem = (cartItemId, newQuantity) => async dispatch => {
  const options = {
    cart_item_id: cartItemId,
    quantity: newQuantity,
    sku: null
  }

  await branditRails.post('/inventory/stock_check_one', options)
}

export const clearCart = locationId => async dispatch => {
  await branditRails
    .post('/cart/clear_all', { location_id: locationId })
    .then(response => {
      if (response && response.status === 200) {
        dispatch({ type: 'CLEAR_CART' })
        toast.success('Your cart has been cleared')
        dispatch({ type: 'CLEAR_SWEETALERT' })
      }
    })
    .catch(err => {
      toast.error(
        "Sorry! We've experienced an error with removing 1 or more products from your cart. Please contact our support team for assistance."
      )
    })
}

// Updates cart item reducer has inventory check. Returns stock check errors if any
export const checkCartStockErrors = userCartId => async dispatch => {
  return await branditRails
    .get(`/cart/${userCartId}/stock_check_errors`)
    .then(resp => {
      if (resp && resp.status === 200) {
        if (resp.data.cart_items.length > 0) {
          dispatch({ type: 'UPDATE_CART_ITEM_INVENTORY', payload: resp.data })
          toast.error('Some items in your cart are out of stock. Please update your cart to continue.')
          return resp.data
        }
      }
    })
    .catch(err => {
      toast.error(
        'Sorry! There was an issue with checking the stock of your cart items. Please try again. If issue presist, please contact our support team for assistance.'
      )
    })
}

// END CART

let abortController // For aborting tax requests

export const fetchTax = formValues => async dispatch => {
  // Abort the previous request
  if (abortController) {
    abortController.abort()
  }

  // Create a new AbortController
  abortController = new AbortController()

  return await branditRails
    .post(`/tax`, formValues, {
      signal: abortController.signal // Pass the signal to your request
    })
    .then(response => {
      if (!response) return

      if (response.data && response.data.tax_status === 'FAILED') {
        if (
          response.data.error_text ===
          'The provided address contains a postal code and state combination that is not valid.'
        ) {
          dispatch({
            type: TAX_ERROR,
            payload: {
              message:
                'ERROR CALCULATING TAX RATE: We couldn’t calculate the tax rate based on the address provided, please review your address to ensure it is entered correctly.'
            }
          })
          dispatch({
            type: FETCH_SHIPPING_QUOTE_ERROR,
            payload:
              'ERROR CALCULATING SHIPPING RATE: We couldn’t calculate the shipping rate based on the address provided, please review your address to ensure it is entered correctly.'
          })
        } else {
          dispatch({
            type: TAX_ERROR,
            payload: {
              message:
                'There has been a problem estimating sales tax on your order, please check your delivery address and try again'
            }
          })
        }
      } else {
        dispatch({ type: CLEAR_TAX_ERROR, payload: response.data })
        dispatch({ type: 'SET_TAX_QUOTE', payload: response.data })
      }
      return response
    })
    .catch(error => {
      if (error?.name === 'AbortError' || error?.code === 'ERR_CANCELED') {
        console.error('Request aborted:', error.message)
      } else {
        // handle error
        dispatch({ type: TAX_ERROR, payload: { message: 'There has been a problem with fetching tax information' } })
      }
    })
}

export const fetchManagedCustomerAddresses = (user_id, portal_id, location_id) => async dispatch => {
  await branditRails.get(`/customer_addresses/managed_addresses`).then(response => {
    if (response && response.data) {
      dispatch({ type: FETCH_MANAGED_CUSTOMER_ADDRESSES, payload: response.data })
    }
    dispatch({ type: CLEAR_MANAGED_CUSTOMER_ADDRESSES_ERROR, payload: '' })
  })
}

// CUSTOMER ADDRESSES
export const fetchCustomerAddresses = (user_id, portal_id) => async dispatch => {
  dispatch({ type: 'SET_CUSTOMER_ADDRESSES_LOADING', payload: true })
  const response = await branditRails.get(`/customer_addresses/${portal_id}/${user_id}`)
  if (response.data) {
    dispatch({ type: FETCH_CUSTOMER_ADDRESSES, payload: response.data })
  }
  dispatch({ type: 'SET_CUSTOMER_ADDRESSES_LOADING', payload: false })
  if (response.data.length === 0) {
    dispatch({ type: 'NO_ADDRESSES_PRESENT', payload: true })
  } else {
    dispatch({ type: 'NO_ADDRESSES_PRESENT', payload: false })
  }
  return response.data
}

// validate address, then ask User which to keep
export const validateAddress =
  ({ addressData, onSaveAddress, callbackFunction, isInternationalShipping }) =>
  async dispatch => {
    return await branditRails
      .post(`/customer_addresses/check_address_validation`, addressData)
      .then(response => {
        // create object from original address
        const originalAddress = { ...addressData, is_valid: false }
        // get suggested address from API response
        const matchedAddress = { ...response.data[0].matched_address }
        // matched_address doesn't have all fields - fill in the values to display
        const validatedAddress = mergeAddressData(originalAddress, matchedAddress)

        const addressError = response.data[0].messages?.some(message => message.code === 'a1004')

        // check if suggested address is present
        const suggestedAddressFound =
          matchedAddress &&
          !_.isEmpty(matchedAddress) &&
          Object.entries(matchedAddress)
            .filter(arr => arr[0] !== 'address_residential_indicator')
            .some(arr => arr[1]) &&
          !addressError
        // build sweetalert with address data
        const customButtonsForSweetAlert = () => {
          return (
            <Container>
              <Row>
                <Col xs={suggestedAddressFound ? 6 : 12}>
                  {formatAddress(originalAddress, 'Original Address', isInternationalShipping)}
                  <Button
                    className="w-100 mf-outline-btn"
                    onClick={() => onSaveAddress(originalAddress, callbackFunction)}
                  >
                    <strong>Use Original Address</strong>
                  </Button>
                </Col>
                {suggestedAddressFound ? (
                  <Col xs="6">
                    {formatAddress(validatedAddress, 'Suggested Address', isInternationalShipping)}
                    <Button
                      className="w-100 mf-primary-btn"
                      onClick={() => onSaveAddress(validatedAddress, callbackFunction)}
                    >
                      <strong>Use Suggested Address </strong>
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </Container>
          )
        }
        const sweetAlertMessage = suggestedAddressFound
          ? 'We found the following suggested address:'
          : 'No address suggestions found.'

        dispatch(
          dispatchSweetAlert({
            customButtons: customButtonsForSweetAlert(),
            alertMessage: sweetAlertMessage,
            showCloseButton: true
          })
        )
      })
      .catch(err => console.error(err))
  }

export const createCustomerAddress = (user_id, portal_id, values) => async dispatch => {
  return branditRails
    .post(`/customer_addresses/${portal_id}/${user_id}`, values)
    .then(response => {
      dispatch({ type: CREATE_CUSTOMER_ADDRESS, payload: response.data })
      toast.info('Address has been added', {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.DANGER,
        className: 'success-toast',
        autoClose: 1200
      })
      return true
    })
    .catch(err => {
      toast.error(err)
    })
}

// same as above but doesn't dispatch redux and returns address from response
export const createCustomerAddressNew =
  ({ user_id, portal_id, addressData, callback }) =>
  async dispatch => {
    return branditRails
      .post(`/customer_addresses/${portal_id}/${user_id}`, addressData)
      .then(response => {
        dispatch({ type: CREATE_CUSTOMER_ADDRESS, payload: response.data })
        if (callback) {
          // callback(response.data)
          // addresses are ordered by ID descending
          callback(response.data[0])
        }
        toast.info('Address has been added', {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.DANGER,
          className: 'success-toast',
          autoClose: 1200
        })
        return true
      })
      .catch(err => {
        toast.error(err)
      })
      .finally(() => {
        dispatch(clearSweetAlert())
      })
  }

export const updateCustomerAddress =
  ({ address_id, values, callback = () => {} }) =>
  async dispatch => {
    return await branditRails
      .patch(`/customer_addresses/${address_id}`, values)
      .then(response => {
        // const updatedAddress = response.data.find(add => add.id === address_id)
        dispatch({ type: UPDATE_CUSTOMER_ADDRESS, payload: response.data })
        toast.info('Address has been updated', {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.DANGER,
          className: 'success-toast',
          autoClose: 1200
        })
      })
      .catch(err => {
        toast.error('Could not save address - please check values and try again')
      })
      .finally(() => {
        dispatch(clearSweetAlert())
        callback()
      })
  }

export const deleteTraditionalAddress = (id, user_id, portal_id, address_type) => async dispatch => {
  await branditRails
    .delete(`/customer_addresses/${id}?address_type=${address_type}&user_id=${user_id}&portal_id=${portal_id}`)
    .then(function (response) {
      if (response.data.errors) {
        toast.error(response.data.errors)
      } else {
        dispatch({ type: DELETE_CUSTOMER_ADDRESS, payload: response.data })
        toast.info('Address deleted successfully!', {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.SUCCESS,
          className: 'address-toast',
          autoClose: 1500
        })
      }
    })
}

// ORDERS
export const fetchClientOrders = user_id => async dispatch => {
  try {
    const ordersResponse = await branditRails.get(`/orders/client/${user_id}`)

    dispatch({ type: FETCH_CLIENT_ORDERS, payload: ordersResponse.data })
  } catch (error) {
    console.error('Error fetching orders and net terms:', error)
  }
}

export const updateOrder = (order_id, value) => async dispatch => {
  await branditRails.put(`/orders/${order_id}`, value).then(response => {
    dispatch({ type: UPDATE_ORDER, payload: response.data })
  })
}

// for checkout session token
export const createToken = (locationId, userId) => async dispatch => {
  dispatch({ type: CLEAR_ERRORS, payload: '' })

  const formData = new FormData()
  formData.append('user_id', userId)
  formData.append('location_id', locationId)

  await branditRails.post('/create_token', formData).then(response => {
    if (response.data) {
      dispatch({ type: 'CREATE_CHECKOUT_TOKEN', payload: response.data })
    }
  })
}

/** Get Shipping Quote */
export const fetchShippingQuote =
  (customerAddress, cartItems, multiAddressSelection = []) =>
  async dispatch => {
    const formData = new FormData()
    dispatch({ type: 'CLEAR_SHIPPING_ERRORS' })
    dispatch({ type: 'SET_SHIPPING_LOADING', payload: true })

    const destination = {
      region: customerAddress.state,
      // smart addresses do not have country_code
      country: customerAddress.address1 ? customerAddress.country : customerAddress.country_code,
      city: customerAddress.city,
      zipcode: customerAddress.zip_code ? customerAddress.zip_code : customerAddress.zip, // for smart address
      street: customerAddress.address1 ? customerAddress.address1 : customerAddress.address_line_1,
      street2: customerAddress.address2 ? customerAddress.address2 : customerAddress.address_line_2
    }

    const cart_items = cartItems.map(item => {
      return {
        cart_item_id: item.cart_item_id,
        product_id: item.product_id,
        qty: item.quantity,
        shippable_type: item.shippable_type,
        product_detail_id: item.product_detail_id
      }
    })
    formData.append('cart_items', JSON.stringify(cart_items))
    formData.append('destination', JSON.stringify(destination))
    formData.append('multiple_address_selections', JSON.stringify(multiAddressSelection))
    formData.append('order_id', 0)
    formData.append('session_id', Math.random())

    return await branditRails
      .post('/shipping', formData)
      .then(response => {
        if (response && response.data && response.data.totalCharges >= 0) {
          dispatch(clearErrors())
          dispatch({ type: 'SET_SHIPPING_QUOTE', payload: response.data })
          return true
        } else {
          dispatch({ type: FETCH_SHIPPING_QUOTE_ERROR, payload: response.data.error })
          dispatch({ type: 'SET_CHECKOUT_ERROR', payload: { shipping: true } })
          return false
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          dispatch({ type: FETCH_SHIPPING_QUOTE_ERROR, payload: error.response.data.error })
          dispatch({ type: 'SET_CHECKOUT_ERROR', payload: { shipping: true } })
        } else {
          dispatch({ type: PRODUCT_ERROR, payload: { message: error.response.data.error } })
          dispatch({ type: 'SET_CHECKOUT_ERROR', payload: { shipping: true } })
        }
        throw error
      })
      .finally(() => {
        dispatch({ type: 'SET_SHIPPING_LOADING', payload: false })
      })
  }

// Promotions
export const clearCoupons = () => async dispatch => {
  dispatch({ type: 'CLEAR_COUPONS' })
}
export const clearPromotions = () => async dispatch => {
  dispatch({ type: 'CLEAR_PROMOTIONS' })
}

export const fetchDiscounts = discount_params => async dispatch => {
  dispatch(clearPromotions())
  await branditRails.post(`/promotions/process_discounts`, discount_params).then(function (response) {
    dispatch({ type: CLEAR_CODE_ERRORS, payload: '' })
    dispatch({ type: CLEAR_SUCCESS, payload: '' })
    if (response && response.data && response.data.status) {
      const promoData = JSON.parse(response.data.message)
      dispatch({ type: 'APPLY_PROMOTION', payload: promoData })
    }
  })
}

export const fetchCoupon = coupon_params => async dispatch => {
  await branditRails.post(`/promotions/process_coupon_code`, coupon_params).then(function (response) {
    dispatch({ type: CLEAR_CODE_ERRORS, payload: '' })
    dispatch({ type: CLEAR_SUCCESS, payload: '' })
    if (response.data.status) {
      const toastId = 'coupon-toast'
      toast.success('Coupon Applied Successfully', { toastId })

      const promoData = JSON.parse(response.data.message)
      dispatch({ type: 'APPLY_PROMOTION', payload: promoData })
    } else {
      dispatch({ type: CREATE_CODE_ERROR, payload: response.data })
    }
  })
}

export const fetchOrderDetails = order_id => async dispatch => {
  await branditRails.get(`/orders/${order_id}/details`).then(response => {
    dispatch({ type: FETCH_ORDER_DETAILS, payload: response.data, id: order_id })
  })
}

export const generateOrderInvoice =
  (order_id, callback = null) =>
  async dispatch => {
    try {
      const response = await branditRails.get(`/orders/${order_id}/generate_invoice`)
      dispatch({ type: FETCH_ORDER_INVOICE, payload: response.data, id: order_id })

      if (callback && typeof callback === 'function') {
        callback(response.data)
      }

      return response
    } catch (err) {
      toast.error(err)
    }
  }

export const fetchDPPreview = (data, config) => async dispatch => {
  dispatch({ type: CLEAR_ERRORS, payload: '' })
  clearDpPreview()
  await branditRails
    .post(`/digital_proofing/dp_preview`, data, config)
    .then(function (response) {
      if (!response.data.items_in_stock) {
        dispatch({ type: PROOFING_PREVIEW, payload: response.data })
      }
    })
    .catch(function (error) {
      dispatch({
        type: CREATE_SESSION_ERROR,
        payload: { 'PDF upload:': 'Something has gone wrong, please contact the administrator' }
      })
      throw error
    })
}

export const clearDpPreview = () => async dispatch => {
  dispatch({ type: 'CLEAR_PROOFING_PREVIEW' })
}

export const dispatchSweetAlert = propsForSweetAlert => async dispatch => {
  dispatch({
    type: 'SHOW_SWEETALERT',
    payload: propsForSweetAlert
  })
}

export const clearSweetAlert = () => async dispatch => {
  dispatch({ type: 'CLEAR_SWEETALERT' })
}

// for legacy compatibility
export const showAlert = propsForSweetAlert => dispatch => {
  dispatch(dispatchSweetAlert(propsForSweetAlert))
}

// ** LOADING
export const setSignInLoading = bool => async dispatch => {
  dispatch({ type: 'SET_SIGN_IN_LOADING', payload: bool })
}

export const setLoading = (key, bool) => async dispatch => {
  dispatch({ type: 'SET_LOADING', payload: { key, bool } })
}

export const fetchMyDownloads =
  (page = 1) =>
  async dispatch => {
    return await branditRails.get(`/my_downloads?page=${page}`).then(resp => {
      if (resp.status === 200) {
        const pagination = JSON.parse(resp.headers['x-pagination'])
        dispatch({
          type: 'SET_MY_DOWNLOADS',
          payload: resp.data,
          total: pagination.total
        })
      }
    })
  }

// Checkout Information Form
export const fetchCheckoutForm = locationId => async dispatch => {
  const response = await branditRails.get(`/site_location/${locationId}/checkout_form`)
  dispatch({ type: 'FETCH_CHECKOUT_FORM', payload: response.data })
}
