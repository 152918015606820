import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import alertReducer from './alertReducer'
import bundleReducer from './bundleReducer'
import bundleSelectionReducer from './bundleSelectionReducer'
import cartItemsReducer from './cartItemsReducer'
import cartMultipleManagedAddressReducer from './cartMultipleManagedAddressReducer'
import categoriesReducer from './categoriesReducer'
import checkoutFormReducer from './checkoutFormReducer'
import checkoutReducer from './checkoutReducer'
import clientOrdersReducer from './clientOrdersReducer'
import creditsReducer from './creditsReducer'
import currentLocationReducer from './currentLocationReducer'
import currentUserReducer from './currentUserReducer'
import customerAddressReducer from './customerAddressReducer'
import customerGroupsReducer from './customerGroupsReducer'
import errorsReducer from './errorsReducer'
import featuredCategoriesReducer from './featuredCategoriesReducer'
import loadingReducer from './loadingReducer'
import locationsReducer from './locationsReducer'
import managedCustomerAddressReducer from './managedCustomerAddressReducer'
import maxQuantitiesReducer from './maxQuantitiesReducer'
import myDownloadsReducer from './myDownloadsReducer'
import orderDetailsReducer from './orderDetailsReducer'
import orderFallbackReducer from './orderFallbackReducer'
import orderInvoiceReducer from './orderInvoiceReducer'
import pagesReducer from './pagesReducer'
import paginatedProductsReducer from './paginatedProductsReducer'
import passwordTokenReducer from './passwordTokenReducer'
import portalPagesReducer from './portalPagesReducer'
import portalReducer from './portalReducer'
import productPageReducer from './productPageReducer'
import productsReducer from './productsReducer'
import proofingPreviewReducer from './proofingPreviewReducer'
import saveProductSuggestionsReducer from './saveProductSuggestionsReducer'
import searchQueryReducer from './searchQueryReducer'
import sessionReducer from './sessionReducer'
import statusReducer from './statusReducer'
import stockReducer from './stockReducer'
import successReducer from './successReducer'
import tokenReducer from './tokenReducer'
import userReducer from './userReducer'

const reducers = combineReducers({
  sweetAlert: alertReducer,
  bundle: bundleReducer,
  bundle_selections: bundleSelectionReducer,
  cartItems: cartItemsReducer,
  cart_multiple_managed_customer_addresses: cartMultipleManagedAddressReducer,
  categories: categoriesReducer,
  checkout: checkoutReducer,
  checkoutForm: checkoutFormReducer,
  client_orders: clientOrdersReducer,
  currentLocation: currentLocationReducer,
  currentUser: currentUserReducer,
  customerGroup: customerGroupsReducer,
  customer_addresses: customerAddressReducer,
  errors: errorsReducer,
  featuredCategories: featuredCategoriesReducer,
  form: formReducer,
  loading: loadingReducer,
  locations: locationsReducer,
  maxQuantities: maxQuantitiesReducer,
  myDownloads: myDownloadsReducer,
  order_details: orderDetailsReducer,
  order_fallbacks: orderFallbackReducer,
  order_invoice: orderInvoiceReducer,
  pages: pagesReducer,
  paginated_products: paginatedProductsReducer,
  portal: portalReducer,
  portal_pages: portalPagesReducer,
  products: productsReducer,
  productPage: productPageReducer,
  productSuggestions: saveProductSuggestionsReducer,
  proofing_preview: proofingPreviewReducer,
  session: sessionReducer,
  searchQuery: searchQueryReducer,
  managed_customer_addresses: managedCustomerAddressReducer,
  status: statusReducer,
  stocks: stockReducer,
  success_message: successReducer,
  token: tokenReducer,
  user: userReducer,
  userCredits: creditsReducer,
  password_token: passwordTokenReducer
})

export default reducers
