import debounce from 'lodash.debounce'
import { useEffect, useRef, useCallback } from 'react'
// import { fetchGrandTotal } from './checkoutFunctions'

const useRecalculateGrandTotal = props => {
  const lastFetchedValues = useRef(null)
  const activeFetchId = useRef(null)

  const fetchGrandTotal = useCallback(
    debounce(values => {
      const fetchId = Symbol()
      activeFetchId.current = fetchId

      props.setCheckoutLoading(true)
      props
        .fetchGrandTotal(
          values.subtotal,
          values.taxQuote,
          values.shippingQuote,
          values.creditsUsed,
          values.appliedPromotions,
          values.appliedCoupon
        )
        .finally(() => {
          if (activeFetchId.current === fetchId) {
            props.setCheckoutLoading(false)
          }
        })
      // .catch(() => {
      //   if (activeFetchId.current === fetchId) {
      //     props.setCheckoutLoading(false)
      //   }
      // })
    }, 1000), // Adjust debounce delay as needed
    []
  )

  useEffect(() => {
    const currentValues = {
      subtotal: props.subtotal,
      taxQuote: props.checkout.taxQuote,
      shippingQuote: props.checkout.shippingQuote,
      creditsUsed: props.checkout.creditsUsed,
      appliedPromotions: props.checkout.appliedPromotions,
      appliedCoupon: props.checkout.appliedCoupon
    }

    // compare current values to last fetched values
    if (
      lastFetchedValues.current &&
      Object.keys(currentValues).every(key => currentValues[key] === lastFetchedValues.current[key])
    ) {
      return
    }

    lastFetchedValues.current = currentValues
    fetchGrandTotal(currentValues)
  }, [
    props.subtotal,
    props.checkout.taxQuote,
    props.checkout.shippingQuote,
    props.checkout.creditsUsed,
    props.checkout.appliedPromotions,
    props.checkout.appliedCoupon,
    fetchGrandTotal
  ])
}

export default useRecalculateGrandTotal
