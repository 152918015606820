import React, { Component } from 'react'
import { fetchDPPreview } from '../../../actions'
import { connect } from 'react-redux'
import ContentLoader from '../../global/loaders/ContentLoader'
import DigitalProofingAndArtworkForm from '../DigitalProofingForms/DigitalProofingAndArtworkForm'

class BundleProofProduct extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedArtwork: null,
      selectedCoupon: null,
      selectedAddOn: null,
      formComplete: false,
      formData: {},
      proof1: false,
      proof2: false,
      previewLoading: false,
      productLoading: false
    }

    this.selectArtwork = this.selectArtwork.bind(this)
    this.selectCoupon = this.selectCoupon.bind(this)
    this.formComplete = this.formComplete.bind(this)
    this.cancelProof = this.cancelProof.bind(this)
    this.complete = this.complete.bind(this)
  }

  componentDidMount() {
    if (this.props.portal.managed_address_book) {
      let customerAddresses = []
      if (this.props.managed_customer_addresses && this.props.managed_customer_addresses.doors_hubs) {
        this.props.managed_customeraddresses.doors_hubs.map(address => {
          customerAddresses.push({
            title: address.name,
            full_name: '',
            address_line_1: address.address1,
            address_line_2: address.address2,
            city: address.city,
            state: address.state,
            zip_code: address.zip
          })
        })
      }
    }
  }

  selectArtwork(value) {
    this.setState({ selectedArtwork: value })
  }
  selectCoupon(value) {
    this.setState({ selectedCoupon: value })
  }
  selectAddon(value) {
    this.setState({ selectedAddOn: value })
  }

  formComplete(formData) {
    this.setState({ previewLoading: true })
    let data = {
      digital_proofing_form: formData,
      product_id: this.props.product.id,
      artwork_id: this.state.selectedArtwork,
      coupon_id: this.state.selectedCoupon !== -1 ? this.state.selectedCoupon : null,
      addon_id: this.state.selectedAddOn !== -1 ? this.state.selectedAddOn : null
    }

    this.props.fetchDPPreview(data).then(() => {
      this.setState({
        formData: formData,
        formComplete: true,
        previewLoading: false
      })
    })
  }

  cancelProof() {
    this.setState({
      formData: {},
      formComplete: false
    })
  }

  handleCheckbox = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  complete() {
    this.props.complete(this.props.index, this.state.formData, this.state.selectedArtwork, this.state.selectedCoupon)
    this.setState({ formComplete: false })
  }

  render() {
    if (this.state.productLoading || this.state.previewLoading) {
      return <ContentLoader />
    }

    // below props are for bundle proofing
    const { customerGroups, quantity, addProductToBasket } = this.props

    return (
      <div className="w-100">
        <div>
          {/* Digital Proofing Form */}
          <DigitalProofingAndArtworkForm
            isBundleProduct={true}
            product={this.props.product}
            customerGroups={customerGroups}
            quantity={quantity}
            addProductToBasket={addProductToBasket}
            selectedArtwork={this.state.selectedArtwork}
            setSelectedArtwork={this.selectArtwork}
            selectedCoupon={this.state.selectedCoupon}
            setSelectedCoupon={this.selectCoupon}
            collapseDpForm={this.props.collapseDpForm}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    locations: Object.values(state.locations),
    portal: state.portal,
    customer_addresses: Object.values(state.customer_addresses).filter(address => address.address_type === 'delivery'),
    mananged_customer_addresses: state.mananged_customer_addresses,
    currentLocation: state.currentLocation,
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps, {
  fetchDPPreview
})(BundleProofProduct)
