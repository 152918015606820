import {
  CREATE_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_ADDRESSES,
  UPDATE_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS,
} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_ADDRESSES:
      return action.payload || state
    case CREATE_CUSTOMER_ADDRESS:
      return action.payload || state
    case UPDATE_CUSTOMER_ADDRESS:
      return action.payload || state
    case DELETE_CUSTOMER_ADDRESS:
      return action.payload || state
    default:
      return state
  }
}
