import {
  CREATE_SESSION_ERROR,
  CREATE_CART_ERROR,
  CLEAR_ERRORS,
  STOCK_CHECK_BULK_FAILURE,
  FETCH_SHIPPING_QUOTE_ERROR,
  CREATE_CODE_ERROR,
  CLEAR_CODE_ERRORS,
  TAX_ERROR,
  CLEAR_TAX_ERROR,
  FETCH_MANAGED_CUSTOMER_ADDRESSES_ERROR,
  CLEAR_MANAGED_CUSTOMER_ADDRESSES_ERROR,
  PRODUCT_ERROR
} from '../actions/types'

const initialState = {
  code_errors: null,
  user_errors: null,
  shipping_errors: null,
  managed_customer_addresses_errors: null,
  tax_errors: null,
  out_of_stock: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_USER_ERROR':
      return { ...state, ['user_errors']: action.payload }
    case CREATE_SESSION_ERROR:
      const formattedErrors = {}
      let i = 0
      for (var key in action.payload) {
        formattedErrors[i] = { message: `${key} ${action.payload[key]}` }
        i++
      }
      return formattedErrors
    // return action.payload;
    case CREATE_CART_ERROR:
      const cartErrors = {}
      let j = 0
      for (var key in action.payload) {
        formattedErrors[j] = { message: `${key} ${action.payload[key]}` }
        j++
      }
      return cartErrors
    case FETCH_SHIPPING_QUOTE_ERROR:
      return { ...state, ['shipping_errors']: action.payload }
    case 'CLEAR_SHIPPING_ERRORS':
      return { ...state, ['shipping_errors']: null }
    case FETCH_MANAGED_CUSTOMER_ADDRESSES_ERROR:
      return { ...state, ['managed_customer_addresses_errors']: action.payload }
    case CREATE_CODE_ERROR:
      return { ...state, ['code_errors']: action.payload }
    // return action.payload;
    case CLEAR_ERRORS:
      return action.payload
    case STOCK_CHECK_BULK_FAILURE:
      return { ...state, ['out_of_stock']: action.payload }
    case CLEAR_MANAGED_CUSTOMER_ADDRESSES_ERROR:
      return { ...state, ['managed_customer_addresses_errors']: null }
    case CLEAR_CODE_ERRORS:
      return { ...state, ['code_errors']: null }
    case CLEAR_TAX_ERROR:
      return { ...state, ['tax_errors']: null }
    case TAX_ERROR:
      return { ...state, ['tax_errors']: action.payload }
    case PRODUCT_ERROR:
      return { ...state, ['product_error']: action.payload }
    default:
      return state
  }
}
