import Bugsnag from '@bugsnag/js'
import { Popup } from 'devextreme-react/popup'
import _, { isEmpty } from 'lodash'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Alert, Button, Card, CardBody, Col, Row, Spinner, Progress, Container, Table } from 'reactstrap'
import { compose } from 'redux'
import {
  dispatchSweetAlert,
  clearSweetAlert,
  fetchUserCredit,
  fetchUser,
  checkCartStockErrors,
  fetchNetTerm
} from '../../actions/index'
import branditRails from '../../api/brandit_rails'
import { standardizeAddress } from '../../util/utils'
import dollar_price from '../general/DollarPrice'
import { setOrderProcessing } from './checkoutFunctions'
import 'devextreme/dist/css/dx.light.css'

const CheckoutConfirm = props => {
  const [loading, setLoading] = useState(false)
  const [addressesStandardized, setAddressesStandardized] = useState({ shipping: {}, billing: {} })
  const [transactionId, setTransactionId] = useState('')
  const [orderSuccess, setOrderSuccess] = useState(false)
  const [orderError, setOrderError] = useState({
    step: '', // one of: token, payment, fallback, order, orderItems
    message: '',
    endpoint: '',
    apiResponse: {},
    control: ''
  })
  const [orderInProgress, setOrderInProgress] = useState(false)
  const [stockCheckLoading, setStockCheckLoading] = useState(false)

  // handle errors for each step in order process
  useEffect(() => {
    const errorObject = {}
    if (orderError.step) {
      switch (orderError.step) {
        case 'order':
          errorObject.message = 'Order creation failure'
          break

        default:
          break
      }
      dispatchErrorAlert(
        orderError,
        process.env.REACT_APP_REACT_ENV === 'production', // notifyBugsnag
        props.portal
      )
    }
  }, [orderError.step])

  // Prevent refresh on order confirmation
  useEffect(() => {
    if (orderInProgress) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [orderInProgress])

  // On order success
  useEffect(() => {
    if (orderSuccess) {
      props.fetchUser(props.portal.default_location).then(() => {
        // Allow progress bar to reach 100% before redirect
        setTimeout(() => {
          setOrderInProgress(false)
          redirectToMyOrders()
        }, 100)
      })
    }
  }, [orderSuccess])

  useEffect(() => {
    setAddressesStandardized({
      shipping: standardizeAddress(props.selectedCustomerAddress),
      billing: standardizeAddress(props.selectedBillingAddress)
    })
  }, [props.selectedCustomerAddress, props.selectedBillingAddress])

  const dispatchErrorAlert = (error = {}, notifyBugsnag = false, portal) => {
    if (notifyBugsnag === true) {
      Bugsnag.addMetadata('apiResponse', error)
      Bugsnag.addMetadata('endpoint', error.endpoint)
      Bugsnag.notify(new Error(error.message ? error.message : ''))
    }
    props.dispatchSweetAlert({
      customButtons: errorMessage(portal, error),
      closeOnClickOutside: false
    })
  }

  // Error message component

  // SN_CHECK REMOVE FALBACK ID AND MAKE CONTROL WORK
  const errorMessage = (portal, error) => {
    const payments = error.apiResponse.payments
    return (
      <div>
        <h3 className="order-error-h3">Order Processing Error</h3>
        <Card>
          <CardBody style={{ paddingTop: '6px', paddingBottom: '6px!important' }}>
            <br />
            <div className="mb-2">
              Error code:{' '}
              {error && error.apiResponse && error.apiResponse.control && error.apiResponse.control.replace('_', '')} |{' '}
              {error.apiResponse.log_tracker ? `Ref #: ${error.apiResponse.log_tracker}` : null}
            </div>
            <Alert color="danger">
              {error.apiResponse.user_message !== 0 ? (
                <div>
                  <b>{error.apiResponse.user_message}</b>
                </div>
              ) : null}
            </Alert>
            <div className="order-error-contact-info">
              {portal.packing_slip_support_email_address} | {portal.packing_slip_support_phone_number}
              <br />
            </div>
          </CardBody>
        </Card>
        <br />
        <Card style={{ backgroundColor: '#E5E4E2', color: '363636' }}>
          <CardBody style={{ paddingTop: '6px', paddingBottom: '1px' }}>
            {payments && payments.total_value == 0 ? (
              <div style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                Your card or any credits you may have used <br />
                <strong>have not been charged</strong>.
              </div>
            ) : (
              <div>
                <div style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                  These transactions <b>have been processed</b>:
                </div>
                <br />
                <Table>
                  <thead>
                    <tr>
                      <th className="fw-right">Payment Method</th>
                      <th className="fw-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments && payments.account_credits_value > 0 ? (
                      <tr>
                        <td className="fw-right">{localStorage.getItem('account_credit_def')}</td>
                        <td className="fw-right">{dollar_price(payments.account_credits_value, 2, null, false)}</td>
                      </tr>
                    ) : null}
                    {payments && payments.coop_value > 0 ? (
                      <tr>
                        <td className="fw-right">{localStorage.getItem('coop_def')}</td>
                        <td className="fw-right">{dollar_price(payments.coop_value, 2, null, false)}</td>
                      </tr>
                    ) : null}
                    {payments && payments.credit_card_value > 0 && payments.net_30_value == 0 ? (
                      <tr>
                        <td className="fw-right">Credit Card</td>
                        <td className="fw-right">{dollar_price(payments.credit_card_value, 2, null, false)}</td>
                      </tr>
                    ) : null}
                    {payments && payments.net_30_value > 0 ? (
                      <tr>
                        <td className="fw-right">Net 30</td>
                        <td className="fw-right">${payments.net_30_value}</td>
                      </tr>
                    ) : null}
                    {payments && payments.gift_card_value > 0 ? (
                      <tr>
                        <td className="fw-right">Gift Card</td>
                        <td className="fw-right">{dollar_price(payments.gift_card_value, 2, null, false)}</td>
                      </tr>
                    ) : null}
                    {payments && payments.points_value > 0 ? (
                      <tr>
                        <td className="fw-right">Points</td>
                        <td className="fw-right">{payments.points_value * props.currentLocation.conversion_ratio}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td className="fw-right">
                        <b>Total</b>
                      </td>
                      <td className="fw-right">
                        <b>
                          {payments && payments.points_value
                            ? payments.points_value * props.currentLocation.conversion_ratio
                            : dollar_price(payments && payments.total_value, 2, null, false)}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>

        <br />
        {error && error.apiResponse && error.apiResponse.control === '_ERR1' ? (
          <>
            <Button className="mf-primary-btn" style={{ marginRight: '10px' }} onClick={handleViewMyAccount} size="lg">
              View My Account
            </Button>
            <Button className="mf-outline-btn" onClick={handleTryAgain} size="lg">
              Try Again
            </Button>
          </>
        ) : (
          <Button className="mf-primary-btn" onClick={handleConfirmErrorModal} size="lg">
            OK
          </Button>
        )}
      </div>
    )
  }

  const handleViewMyAccount = () => {
    props.clearSweetAlert()
    props.history.push('/customer/account/dashboard')
  }

  const handleTryAgain = () => {
    props.clearSweetAlert()
    props.hideModal()
    props.history.go(0)
  }

  const handleConfirmErrorModal = () => {
    props.clearSweetAlert()
    props.hideModal()
    initializeOrderProgress()
  }

  const redirectToMyOrders = () => {
    props.history.push({
      pathname: '/customer/account/dashboard',
      tabSelected: 'My Orders',
      confirmationMessage: `Your order has been placed successfully!`
    })
  }

  // Prevent page refresh on order confirmation
  const handleBeforeUnload = event => {
    event.preventDefault()
    event.returnValue = ''
  }

  // Check Stock, Then Create Order
  const handleOrderConfirm = () => {
    setLoading(true)
    setOrderInProgress(true)
    setStockCheckLoading(true)

    props.checkCartStockErrors(props.checkout.userCartId).then(resp => {
      setStockCheckLoading(false)
      if (resp?.cart_items?.length > 0) {
        setLoading(false)
        setOrderInProgress(false)
        props.hideModal()
      } else {
        createOrder()
      }
    })
  }

  // ** Place Order **
  const createOrder = () => {
    const formData = new FormData()
    const checkout = props.checkout
    // Payment
    const { selectedCustomerAddress, selectedBillingAddress, youPay, paymentMethod } = checkout
    const { card_number, card_expiry, card_cvc } = checkout.ccData

    const coopDeduction = checkout.creditsUsed.coopCredits
    const creditDeduction = checkout.creditsUsed.accountCredits
    const giftDeduction = checkout.creditsUsed.giftCard
    const lmsPointsDeduction = checkout.creditsUsed.lmsPoints

    formData.append('payment_coop', coopDeduction)
    formData.append('payment_credit_note', creditDeduction)
    formData.append('payment_gift', giftDeduction)
    formData.append('payment_lms_points', lmsPointsDeduction)

    const trimmedCardNumber = card_number.replace(/\s/g, '')

    formData.append('payment_method', paymentMethod)
    formData.append('total_cents', youPay)

    formData.append('card_number', trimmedCardNumber)
    formData.append('card_expire_date', card_expiry)
    formData.append('card_security_code', card_cvc)
    formData.append('card_number_two_first_digits', trimmedCardNumber.substr(0, 1))
    formData.append('card_last_four_digit', trimmedCardNumber.substr(-4))
    formData.append('payment_amount', parseFloat(youPay / 100).toFixed(2))

    // send back encrypted checkout data
    formData.append('checkout_data', JSON.stringify(checkout.checkoutData))

    // Price totals
    formData.append('tax', checkout.taxQuote)
    formData.append('applied_promotions', JSON.stringify(props.appliedPromotions)) //todo: just send promo id?
    formData.append('shipment_in_cents', checkout.shippingQuote)
    formData.append('items_discount_in_cents', checkout.subtotalReduction + checkout.productReduction)
    formData.append('shipping_discount_in_cents', checkout.shippingReduction)
    formData.append('coop_discount_in_cents', coopDeduction)
    formData.append('subtotal_in_cents', checkout.subtotal)
    formData.append('net_30_confirmed', checkout.net30Confirmed)

    // Cart info
    formData.append('user_cart_id', props.checkout.userCartId)
    formData.append('cart_json', JSON.stringify(props.cartItems))

    // Order info
    if (transactionId) {
      formData.append('transaction_id', transactionId === -1 ? null : transactionId)
    }
    formData.append('po_number', checkout.poNumber)
    formData.append('token', props.token)
    formData.append('customer_notes', checkout.customerNotes)
    if (props.checkoutFormData && props.checkoutFormData.length > 0) {
      formData.append('checkout_form', JSON.stringify(props.checkoutFormData))
    }

    // Shipping info
    formData.append('billing_address', JSON.stringify(selectedBillingAddress))
    formData.append('customer_address', JSON.stringify(selectedCustomerAddress))
    if (props.portal.multiple_address_shipping) {
      formData.append('multi_address_selection', JSON.stringify(props.checkout.multiAddressSelection))
    }

    // if portal does not have managed addresses enabled OR
    // if it DOES but there are no doors_hubs
    if (
      !props.portal.managed_address_book ||
      (props.portal.managed_address_book && !props.managed_customer_addresses.is_system_managed) // TODO: UPdate this
    ) {
      // if selected customer address is present
      if (props.selectedCustomerAddress.id) {
        formData.append('customer_address_id', props.selectedCustomerAddress.id)
      }
      // if selected Customer address is not present but not required
      else if (
        !props.selectedCustomerAddress.id &&
        props.cartItems.every(ci => ci.shippable_type === 'Digital Delivery')
      ) {
        // formData.append('customer_address_id', null);
      }
      // if selected customer address is not present but required - error
      else {
        console.error('Shipping Address Required')
        return
      }

      !props.checkout.youPay === 0 && formData.append('billing_address_id', props.selectedBillingAddress.id)
    }

    // if smart delivery address, append appropriate keys for address & phone
    if (!isEmpty(props.selectedCustomerAddress) && !!props.selectedCustomerAddress.m_able) {
      formData.append('customer_address', JSON.stringify(props.selectedCustomerAddress))
      formData.append('delivery_phone_number', props.selectedCustomerAddress.phone)
    } else {
      // else use phone from address
      formData.append('delivery_phone_number', props.selectedCustomerAddress.address_phone)
    }

    branditRails
      .post('/orders/create', formData)
      .then(response => {
        props.fetchUserCredit()
        if (response && response.data.status === 'success') {
          setOrderSuccess(true)
        } else if (response && response.data.status === 'failed') {
          setOrderInProgress(false)
          setLoading(false)
          setOrderError({
            step: 'order',
            message: 'Order creation failure',
            endpoint: `/orders/create`,
            apiResponse: response.data,
            control: response.data.control
          })
        } else {
          setOrderInProgress(false)
          setLoading(false)
        }
      })
      .catch(error => {
        setOrderError({
          step: 'order',
          message: 'Order creation failure',
          endpoint: `/orders/create`,
          formData,
          apiResponse: error,
          control: null
        })
        setOrderInProgress(false)
        setLoading(false)
      })
  }

  // ** END ORDER FLOW

  const initializeOrderProgress = () => {
    setOrderSuccess(false)
    setTransactionId('')
    setLoading(false)
    setOrderError({})
    props.setOrderProcessing(false)
  }

  useEffect(() => {
    setAddressesStandardized({
      shipping: standardizeAddress(props.selectedCustomerAddress),
      billing: standardizeAddress(props.selectedBillingAddress)
    })
  }, [props.selectedCustomerAddress, props.selectedBillingAddress])

  const resolvePaymentMessage = () => {
    if (props.portal.use_lms_points) {
      return <span>Total Points to be Used: {dollar_price(props.checkout.creditsUsed.lmsPoints)}</span>
    }
    switch (props.checkout.paymentMethod) {
      case 'credit_card':
        return <span>Amount to be Charged to Credit Card: {dollar_price(props.checkout.youPay)}</span>
      case 'net30':
        return (
          <span>
            Payment Amount Due ({props.netTerm || 'Net 30'}): {dollar_price(props.checkout.youPay)}
          </span>
        )
      default:
        return <span>Amount to be Charged to Credit Card: {dollar_price(props.checkout.youPay)}</span>
    }
  }

  const paymentMethod = props.checkout.paymentMethod === 'credit_card' ? 'Credit Card' : 'Net30'
  const hidePhysicalShipping = props.cartItems.every(ci => ci.shippable_type === 'Digital Delivery')
  const showDigitalDeliveryMessage = props.cartItems.some(ci => ci.shippable_type === 'Digital Delivery')

  return (
    <Popup
      closeOnOutsideClick={false}
      visible={props.show}
      showCloseButton={false}
      showTitle={false}
      onHiding={props.hideModal}
      dragEnabled={false}
      height={'auto'}
      width={'auto'}
    >
      <Container className="order-confirmation-container">
        <Row>
          <Col>
            <h2>Order Confirmation</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Please confirm your order. Upon final confirmation your order will be placed.</p>

            {showDigitalDeliveryMessage && (
              <p>
                Some or all items in your cart will be delivered digitally to the email{' '}
                <strong>{props.currentUser.email}</strong>.
              </p>
            )}

            {hidePhysicalShipping ? null : (
              <p>
                <strong>Ship To:</strong> &nbsp;
                {addressesStandardized.shipping.address_line_1}, &nbsp;
                {addressesStandardized.shipping.address_line_2
                  ? `${addressesStandardized.shipping.address_line_2}, `
                  : ''}
                {addressesStandardized.shipping.city}, &nbsp;
                {addressesStandardized.shipping.state}, &nbsp;
                {addressesStandardized.shipping.zip_code}
              </p>
            )}

            {!props.checkout.youPay === 0 ? (
              <span>
                <p>
                  Billing Address: &nbsp;
                  {addressesStandardized.billing.address_line_1}, &nbsp;
                  {addressesStandardized.billing.address_line_2
                    ? `${addressesStandardized.billing.address_line_2}, `
                    : ''}
                  {addressesStandardized.billing.city}, &nbsp;
                  {addressesStandardized.billing.state}, &nbsp;
                  {addressesStandardized.billing.zip_code}
                </p>
                <p>Payment Method: {paymentMethod}</p>
              </span>
            ) : null}

            {props.checkout.creditsUsed.coopCredits ? (
              <div>
                {localStorage.getItem('coop_def')}:&nbsp; -{dollar_price(props.checkout.creditsUsed.coopCredits)}
              </div>
            ) : null}

            {props.checkout.creditsUsed.accountCredits ? (
              <div>
                {localStorage.getItem('account_credit_def')} Payment:&nbsp; -
                {dollar_price(props.checkout.creditsUsed.accountCredits)}
              </div>
            ) : null}

            {props.checkout.creditsUsed.giftCard ? (
              <div>
                <div>Gift Card Credit Payment:&nbsp; -{dollar_price(props.checkout.creditsUsed.giftCard)}</div>
              </div>
            ) : null}

            {props.currentLocation.show_price ? <p className="payment-total">{resolvePaymentMessage()}</p> : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              {loading ? (
                <Spinner color="secondary" />
              ) : (
                <Row>
                  <Col xs={6}>
                    <Button
                      style={{ width: '100%', maxWidth: '200px' }}
                      className="mf-outline-btn"
                      onClick={props.hideModal}
                    >
                      Back to Checkout
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      style={{ width: '100%', maxWidth: '200px' }}
                      className="mf-primary-btn"
                      disabled={loading || !props.canProceed}
                      onClick={handleOrderConfirm}
                    >
                      Confirm Order
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {orderInProgress && (
              <div className="mt-4">
                <Progress animated value={stockCheckLoading ? 25 : orderSuccess ? 100 : 50}>
                  {stockCheckLoading ? 'Checking Stock' : 'Creating Order'}
                </Progress>
                <p className="mt-3 mb-0">
                  <strong>Order in progress. Please do not navigate away from page. </strong>
                </p>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Popup>
  )
}

const mapStateToProps = state => {
  return {
    selectedBillingAddress: state.checkout.selectedBillingAddress,
    selectedCustomerAddress: state.checkout.selectedCustomerAddress,
    checkout: state.checkout,
    portal: state.portal,
    token: state.token,
    cartItems: state.cartItems,
    tax: state.tax,
    appliedPromotions: state.checkout.appliedPromotions,
    userCredits: state.userCredits,
    managed_customer_addresses: state.managed_customer_addresses,
    currentLocation: state.currentLocation,
    orderFallback: state.order_fallbacks.fallback,
    locations: state.locations,
    currentUser: state.currentUser,
    checkoutFormData: state.checkout.checkoutFormData,
    netTerm: state.portal.context_dictionary.net_term_def
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    dispatchSweetAlert,
    clearSweetAlert,
    fetchUserCredit,
    fetchUser,
    setOrderProcessing,
    checkCartStockErrors
  })
)(CheckoutConfirm)
