import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import {
  fetchPortal,
  updateCustomerAddress,
  createCustomerAddress,
  deleteTraditionalAddress,
  validateAddress,
  dispatchSweetAlert,
  createCustomerAddressNew
} from '../../../actions'
import './FormAddressWrapper.css'
import AddAddressModal from '../../addresses/AddAddressModal'
import AddressList from '../../addresses/AddressList'
import EditAddressModal from '../../addresses/EditAddressModal'
import { setBillingAddress, setCustomerAddress } from '../../Checkout/checkoutFunctions'

const FormAddressWrapper = ({
  currentUser,
  setAddressesCallback,
  addressType,
  portal,
  manageAddresses,
  setManageAddresses,
  customer_addresses,
  updateCustomerAddress,
  validateAddress,
  deleteTraditionalAddress,
  dispatchSweetAlert,
  afterCreateAddress,
  ...props
}) => {
  const [newAddressModal, setNewAddressModal] = useState(false)
  const [editAddressModal, setEditAddressModal] = useState(false)
  const [address, setAddress] = useState({})
  const anotherElementRef = useRef(null)
  const isBillingAddress = addressType === 'billing'
  useEffect(() => {
    if (manageAddresses) {
      document.getElementById('testtt').focus()
    }
  }, [manageAddresses])

  const toggleNewAddressModal = () => {
    setNewAddressModal(!newAddressModal)
  }
  const closeNewAddressModal = () => {
    setNewAddressModal(false)
  }

  const toggleEditAddressModal = address => {
    if (editAddressModal) {
      // resetFormFields()
      setEditAddressModal(!editAddressModal)
    } else {
      setAddress(address)
      setEditAddressModal(!editAddressModal)
    }
  }

  const handleDeleteTraditionalAddress = (address_id, address_type) => {
    const isDefaultShipping = customer_addresses.find(
      address => address.id === address_id && address.default_shipping === true
    )
    dispatchSweetAlert({
      title: isDefaultShipping ? 'Confirm Default Address Deletion' : 'Confirm Address Deletion',
      alertMessage: isDefaultShipping
        ? 'This is your default address. Are you sure you want to delete it? You can set another default later if needed.'
        : 'Are you sure you want to delete this address?',
      type: 'warning',
      cancelBtnCssClass: 'mf-outline-btn alert-width-btn',
      showCancel: true,
      onConfirm: () => confirmDeleteTraditionalAddress(address_id, address_type),
      confirmBtnCssClass: 'mf-primary-btn alert-width-btn',
      confirmBtnText: 'Confirm Deletion'
    })
  }

  const confirmDeleteTraditionalAddress = (address_id, address_type) => {
    const user_id = currentUser.id
    const portal_id = portal.id
    deleteTraditionalAddress(address_id, user_id, portal_id, address_type)
  }

  const closeEditModal = () => {
    setEditAddressModal(false)
  }

  const handleUpdateAddress = addressData => {
    const formData = new FormData()
    Object.entries(addressData).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('is_shipping', true)

    updateCustomerAddress({ address_id: addressData.id, values: formData, callback: closeEditModal })
  }

  const afterCreateAddressCallback = address => {
    if (afterCreateAddress) {
      afterCreateAddress(address)
    }
    closeNewAddressModal()
    setManageAddresses(false)
  }

  const handleCreateAddress = addressData => {
    addressData.is_shipping = true
    props.createCustomerAddressNew({
      addressData,
      callback: createAddressCallback,
      isInternationalShipping: portal.is_international_shipping
    })
  }

  const handleValidateAddress = (addressData, method = 'create') => {
    let onSaveAddress
    if (method === 'update') {
      onSaveAddress = handleUpdateAddress
    } else {
      onSaveAddress = handleCreateAddress
    }
    validateAddress({ addressData, onSaveAddress, isInternationalShipping: portal.is_international_shipping })
  }

  const handleClickManageAddresses = () => {
    setManageAddresses(!manageAddresses)
    anotherElementRef.current.focus()
  }

  const showAddNewAddress = (() => {
    const isManaged = portal?.managed_address_book
    const isHybrid = portal?.hybrid_address
    const isHideButton = props.customerGroup.disable_hybrid_shipping

    switch (true) {
      case isManaged && isHybrid && isHideButton:
        return false
      case isManaged && isHybrid && !isHideButton:
        return true
      case isManaged && !isHybrid && !isHideButton:
        return false
      case !isManaged && !isHybrid && !isHideButton:
        return true
      case !isManaged && !isHybrid && isHideButton:
        return true
      case !isManaged && isHybrid && isHideButton:
        return true
      case isManaged && !isHybrid && isHideButton:
        return false
      case !isManaged && isHybrid && !isHideButton:
        return true
      default:
        return false
    }
  })()

  const renderEditCloseButton = () => {
    if (
      (portal.managed_address_book && !portal.hybrid_address && !isBillingAddress) ||
      (!showAddNewAddress && !isBillingAddress)
    ) {
      return null
    }

    if (manageAddresses) {
      return (
        <Button
          id="close-address-mgmt-btn"
          className="mf-outline-danger-btn mt-1.5 float-right mr-3"
          size="sm"
          onClick={handleClickManageAddresses}
        >
          Close Address Management
        </Button>
      )
    } else {
      return (
        <Button
          id="add-edit-address-btn"
          className="mf-outline-btn float-right mr-3"
          size="sm"
          onClick={handleClickManageAddresses}
        >
          Add / Edit Addresses
        </Button>
      )
    }
  }

  return (
    <div className="float-right">
      <div id="testtt" className="dp-address-form pb-3">
        {renderEditCloseButton()}
        {/* dummy div to prevent autoselecting above button */}
        <div tabIndex={-1} ref={anotherElementRef}></div>
        {!showAddNewAddress && !isBillingAddress
          ? null
          : manageAddresses && (
              <Button
                size="sm"
                className="mf-outline-btn mr-3 float-right default-border-radius"
                onClick={toggleNewAddressModal}
              >
                Add New Address
              </Button>
            )}
      </div>
      {manageAddresses && (
        <AddressList
          onEdit={toggleEditAddressModal}
          onDelete={handleDeleteTraditionalAddress}
          addresses={customer_addresses}
          addressType={addressType}
        />
      )}

      <EditAddressModal
        isOpen={editAddressModal}
        toggle={toggleEditAddressModal}
        address={address}
        handleValidateAddress={handleValidateAddress}
        closeEditModal={closeEditModal}
        addressType={addressType}
      />

      <AddAddressModal
        isOpen={newAddressModal}
        toggle={toggleNewAddressModal}
        afterCreateAddress={afterCreateAddressCallback}
        addressType={addressType}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    portal: state.portal,
    customer_addresses: Object.values(state.customer_addresses).filter(address => address.address_type === 'delivery'),
    selectedCustomerAddress: state.checkout.selectedCustomerAddress,
    selectedBillingAddress: state.checkout.selectedBillingAddress,
    currentUser: state.currentUser,
    customerGroup: state.customerGroup
  }
}

export default connect(mapStateToProps, {
  fetchPortal,
  updateCustomerAddress,
  createCustomerAddress,
  deleteTraditionalAddress,
  dispatchSweetAlert,
  setBillingAddress,
  setCustomerAddress,
  validateAddress,
  createCustomerAddressNew
})(FormAddressWrapper)
