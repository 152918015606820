import { filter, cloneDeep } from 'lodash'

const DEV = process.env.NODE_ENV === 'development'
const ccInitialState = {
  payment_amount: '',
  card_number: DEV ? '4242424242424242' : '',
  card_cvc: DEV ? '123' : '',
  card_expiry: DEV ? '1229' : '',
  card_name: DEV ? 'Johann Sebastian Bach' : '',
  focus: '',
  errors: []
}

const initialState = {
  // Payment
  paymentMethod: 'credit_card',
  poNumber: '',
  ccData: cloneDeep(ccInitialState),
  net30Confirmed: false,

  // Addresses
  userHasNoAddresses: false,
  selectedCustomerAddress: {},
  selectedBillingAddress: {},
  multiAddressSelection: [],

  // Shipping
  shippingQuoteValid: false,
  shippingQuote: 0,
  shippingLoading: false,

  // Tax
  // taxQuoteValid: false,
  taxQuote: 0,
  taxLoading: false,
  // {
  //   tax_status: '',
  //   total_tax_cents: 0,
  //   data: ''
  // }

  // Credits
  creditsUsed: {
    giftCard: 0,
    accountCredits: 0,
    coopCredits: 0,
    lmsPoints: 0
  },

  // Discounts & Promotions
  discounts: 0, // {}
  appliedPromotions: [],
  shippingReduction: 0,
  subtotalReduction: 0,
  productReduction: 0,
  taxReduction: 0,

  // Other Fees
  setupCharge: 0,
  customCheckoutFee: 0,

  // Totals
  subtotal: 0,
  grandTotal: 0,
  youPay: 0,

  errors: {
    minQtyError: false,
    incrQtyError: [],
    maxQtyReached: false,
    stock: false,
    shipping: true,
    multiAddress: false
  },
  errorMessage: '',
  cart_loading: false,
  maxQuantityLoading: false,
  checkoutLoading: false,
  managedAddressesLoading: false,
  customerNotes: '',
  orderProcessing: false,
  checkoutFormData: [],

  // Encrypted data
  checkoutData: {
    tax: '',
    youPay: '',
    appliedPromotions: '',
    shipping: ''
  },

  // Use shipping address as billing address
  useAsBilling: true
}

export default (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'CLEAR_PROMOTIONS':
      return { ...state, appliedPromotions: [] }
    case 'CLEAR_COUPONS':
      const nonCouponPromotions = filter(state.appliedPromotions, promo => promo.type !== 'coupon')
      return {
        ...state,
        appliedPromotions: nonCouponPromotions
      }
    // clear multi address selections - provide cart item ID as payload or it will clear all
    case 'CLEAR_MULTI_ADDRESS_SELECTION':
      // if cart item ID is provided, clear only that cart item, else clear all selections
      const cartItemIdToClear = action.payload
      if (cartItemIdToClear) {
        const selectionsWithoutThisCartItem = state.multiAddressSelection.filter(
          selection => selection.cartItemId !== cartItemIdToClear
        )
        return {
          ...state,
          multiAddressSelection: selectionsWithoutThisCartItem
        }
      } else {
        return {
          ...state,
          multiAddressSelection: []
        }
      }

    case 'SET_MULTI_ADDRESS_SELECTION':
      const { cartItemId, selections } = action.payload

      const existingSelectionForThisCartItem = state.multiAddressSelection.filter(
        selection => cartItemId === selection.cartItemId
      )
      const existingSelectionsMinusThisCartItem = state.multiAddressSelection.filter(
        selection => cartItemId !== selection.cartItemId
      )

      // create formatted objects for each address selection on this cart item
      const thisCartItemAddressSelections = selections.map(selection => {
        const addressSelectionData = {
          cartItemId,
          address: selection.address
        }
        // add quantity if present
        if (selection.quantity) {
          addressSelectionData.quantity = selection.quantity
        }
        return addressSelectionData
      })

      return {
        ...state,
        multiAddressSelection: [...existingSelectionsMinusThisCartItem, ...thisCartItemAddressSelections]
      }
    case 'SET_ORDER_PROCESSING':
      return { ...state, orderProcessing: action.payload }
    case 'SET_NET30_CONFIRMED':
      return { ...state, net30Confirmed: action.payload }
    case 'CHECKOUT_CLEANUP':
      return {
        ...cloneDeep(initialState),
        customCheckoutFee: action.payload.custom_checkout_fee
      }
    case 'SET_CHECKOUT_LOADING':
      return { ...state, checkoutLoading: action.payload }
    case 'SET_SHIPPING_QUOTE':
      return {
        ...state,
        shippingQuote: parseInt(action.payload.totalCharges),
        checkoutData: { ...state.checkoutData, shipping: action.payload.data }
      }
    case 'CLEAR_SHIPPING_QUOTE':
      return { ...state, shippingQuote: 0, shippingReduction: 0, multiAddressSelection: [] }
    case 'SET_SHIPPING_LOADING':
      return { ...state, shippingLoading: action.payload }
    case 'SET_PO_NUMBER':
      return { ...state, poNumber: action.payload }
    case 'SET_PAYMENT_METHOD':
      return { ...state, paymentMethod: action.payload }
    case 'SET_APPLIED_COUPON':
      return { ...state, appliedCoupon: action.payload }
    case 'NO_ADDRESSES_PRESENT':
      return { ...state, userHasNoAddresses: action.payload }
    case 'SET_CUSTOMER_ADDRESS':
      return { ...state, selectedCustomerAddress: action.payload }
    case 'SET_BILLING_ADDRESS':
      return { ...state, selectedBillingAddress: action.payload }
    case 'SET_USE_AS_BILLING':
      return { ...state, useAsBilling: action.payload }
    case 'SET_CHECKOUT_ERROR':
      const existingErrors = state.errors
      const incomingErrorsObject = action.payload
      const updatedErrorsObject = Object.assign({}, existingErrors, incomingErrorsObject)
      return { ...state, errors: updatedErrorsObject }
    case 'SET_CUSTOMER_NOTES':
      return { ...state, customerNotes: action.payload }

    // Price
    case 'SET_TAX_QUOTE':
      return {
        ...state,
        taxQuote: action.payload.total_tax_cents,
        checkoutData: { ...state.checkoutData, tax: action.payload.data }
      }
    case 'APPLY_PROMOTION':
      const promotion = action.payload

      const shippingReduction =
        promotion.shipping_reduction > state.shippingQuote ? state.shippingQuote : promotion.shipping_reduction
      const subtotalReduction =
        promotion.subtotal_reduction > state.subtotal ? state.subtotal : promotion.subtotal_reduction

      return {
        ...state,
        appliedPromotions: [promotion],
        shippingReduction,
        subtotalReduction,
        productReduction: promotion.product_reduction_total,
        checkoutData: { ...state.checkoutData, appliedPromotions: promotion.data }
      }
    case 'SET_GRAND_TOTAL':
      return {
        ...state,
        // if total is $0, remove CC data to prevent errors
        ccData: action.payload === 0 || state.paymentMethod === 'net30' ? cloneDeep(ccInitialState) : state.ccData,
        grandTotal: action.payload.you_pay,
        youPay: action.payload.you_pay,
        checkoutLoading: false,
        checkoutData: { ...state.checkoutData, youPay: action.payload.data }
      }
    case 'SET_SUBTOTAL':
      return { ...state, subtotal: action.payload }
    case 'SET_SETUP_CHARGE':
      return { ...state, setupCharge: action.payload }
    case 'SET_CUSTOM_CHECKOUT_FEE':
      return { ...state, customCheckoutFee: action.payload }
    case 'SET_CREDITS_USED':
      const newCreditsUsedObject = action.payload

      // re-calculate totals with new credits:
      const grandTotalWithNewUpdatedCredits = calculateGrandTotal({
        cartItemsSubtotal: state.subtotal,
        totalTax: state.taxQuote,
        totalShipping: state.shippingQuote,
        creditsUsed: newCreditsUsedObject,
        subtotalDiscount: state.subtotalReduction,
        customCheckoutFee: state.customCheckoutFee
      })

      // if total is $0, remove CC data
      return {
        ...state,
        creditsUsed: newCreditsUsedObject,
        ccData:
          grandTotalWithNewUpdatedCredits === 0 || state.paymentMethod === 'net30'
            ? {
                payment_amount: '',
                card_number: '',
                card_cvc: '',
                card_expiry: '',
                card_name: '',
                focus: '',
                errors: []
              }
            : state.ccData,
        youPay: grandTotalWithNewUpdatedCredits,
        grandTotal: grandTotalWithNewUpdatedCredits,
        checkoutLoading: false
      }
    case 'SET_CC_DATA':
      const existingCcData = cloneDeep(state.ccData)
      const newData = Object.assign({}, existingCcData, action.payload)
      return {
        ...state,
        ccData: newData
      }
    case 'UPDATE_CREDIT_CARD': // <--DEPRECATED
      return {
        ...state,
        creditCardData: action.payload,
        errors: validatePayment(action.payload)
      }
    case 'CLEAR_CHECKOUT_ERRORS':
      return { ...state, errors: [] }
    case 'SET_TAX_LOADING':
      return { ...state, taxLoading: action.payload }
    case 'SET_CART_LOADING':
      return { ...state, cart_loading: true }
    case 'CLEAR_CART_LOADING':
      return { ...state, cart_loading: false }
    case 'MAX_QUANTITY_LOADING':
      return { ...state, maxQuantityLoading: action.payload }
    case 'SET_CHECKOUT_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload }
    case 'SET_CHECKOUT_FORM_DATA':
      return { ...state, checkoutFormData: action.payload }
    case 'SET_USER_CART_ID':
      return { ...state, userCartId: action.payload }
    default:
      return state
  }
}

// returns array of error objects
const validatePayment = ccData => {
  const { cvc, number } = ccData
  const errors = []

  // Card number validations
  if (isNaN(number) && number !== '') {
    errors.push('Card number should only contain numbers')
  } else if (number.length > 16) {
    errors.push('Card number should be 16 digits')
  }

  // CVC validations
  if (isNaN(cvc) && cvc !== '') {
    errors.push('CVC should only contain numbers')
  } else if (cvc.length > 4) {
    errors.push('CVC should be 3 or 4 digits')
  }
}

const calculateGrandTotal = (
  options = {
    cartItemsSubtotal: 0,
    totalTax: 0,
    totalShipping: 0,
    creditsUsed: 0,
    subtotalDiscount: 0,
    customCheckoutFee: 0
  }
) => {
  const { cartItemsSubtotal, totalTax, totalShipping, creditsUsed, subtotalDiscount, customCheckoutFee } = options
  let result = cartItemsSubtotal
  // Subtotal Discount
  result -= subtotalDiscount
  // Co-Op
  result -= creditsUsed.coopCredits
  // Account Credits
  result -= creditsUsed.accountCredits
  // Gift Cards
  result -= creditsUsed.giftCard
  // LMS Points
  result -= creditsUsed.lmsPoints

  // Shipping/Tax
  result += totalShipping
  result += totalTax

  // Custom Checkout Fee (Location Based)
  result += customCheckoutFee

  // Cash (CC, Check, Net Terms, etc.)

  return result < 0 ? 0 : result
}
