// This file returns an array of country objects for supported countries.

import { Country, State, City } from 'country-state-city'

// This array of controls the supported countries.
// Add a country code and the system will include it.
// "definitions" indicates which language to use for the address, 0 for default (see below)
// definitions: postcode, city, region
const supportedCountryList = [
  {
    country_code: 'GB',
    definitions: [1, 0, 2]
  },
  {
    country_code: 'US',
    definitions: [0, 0, 0]
  },
  {
    country_code: 'CA',
    definitions: [3, 0, 1]
  },
  {
    country_code: 'PR',
    definitions: [0, 3, 4]
  },
  {
    country_code: 'IN',
    definitions: [4, 0, 0]
  },
  {
    country_code: 'PH',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'AU',
    definitions: [0, 2, 0]
  },
  {
    country_code: 'SG',
    definitions: [0, 1, 4]
  },
  {
    country_code: 'ES',
    definitions: [0, 0, 1]
  },
  {
    country_code: 'DE',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'NL',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'SE',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'FR',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'AT',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'DK',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'BE',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'CN',
    definitions: [0, 0, 1]
  },
  {
    country_code: 'HK',
    definitions: [5, 4, 5]
  },
  {
    country_code: 'JP',
    definitions: [0, 0, 6]
  },
  {
    country_code: 'EE',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'PT',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'RU',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'CR',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'PL',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'IE',
    definitions: [6, 0, 7]
  },
  {
    country_code: 'LT',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'ZA',
    definitions: [0, 0, 1]
  },
  {
    country_code: 'UA',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'HN',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'AL',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'BY',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'GE',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'IL',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'LK',
    definitions: [0, 0, 4]
  },
  {
    country_code: 'IT',
    definitions: [0, 0, 1]
  },
  {
    country_code: 'GR',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'NG',
    definitions: [5, 0, 0]
  },
  {
    country_code: 'CH',
    definitions: [0, 0, 8]
  },
  {
    country_code: 'GD',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'PK',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'TN',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'MX',
    definitions: [3, 0, 0]
  },
  {
    country_code: 'KN',
    definitions: [0, 0, 3]
  },
  {
    country_code: 'CO',
    definitions: [5, 0, 3]
  }
]

// Define language specific to certain countries
const postcodeDefinitions = [
  'Postcode/Zip', // 0
  'Postcode', // 1
  'Zip', // 2
  'Postal Code', // 3
  'Pin Code', // 4
  'Postcode/Zip (Optional)', // 5
  'Eircode (Optional)' // 6
]
const townCityDefinitions = [
  'Town/City', // 0
  'Town/City (Optional)', // 1
  'Suburb', // 2
  'Municipality', // 3
  'Town/District' // 4
]
const stateProvinceDefinitions = [
  'State', // 0
  'Province', // 1
  'County (Optional)', // 2
  'State/County', // 3
  'N/A', // 4 - will cause the field to be hidden
  'Region', // 5
  'Prefecture', // 6
  'County', // 7
  'Canton (Optional)' // 8
]

// Returns array of country objects formatted with labels
// required parameter: portal.supported_countries
export const supportedCountries = portalSupportedCountries => {
  if (!portalSupportedCountries) {
    return []
  }
  const allCountries = Country.getAllCountries()
  // filter only supported countries
  return allCountries
    .filter(countryData => {
      // get array of codes to check
      const supportedCountryCodes = supportedCountryList.map(c => c.country_code)

      // remove any countries not supported by portal
      const portalFilteredCountryCodes = supportedCountryCodes.filter(code => portalSupportedCountries.includes(code))

      const isCountrySupported = portalFilteredCountryCodes.includes(countryData.isoCode)
      return isCountrySupported
    })
    .map(countryData => {
      // merge country data from API and local definitions
      const localCountryData = supportedCountryList.find(c => c.country_code === countryData.isoCode)
      return createCountry(localCountryData, countryData)
    })
}

const createCountry = (countryFromList, countryData) => {
  const [postcodeLabelIndex, cityLabelIndex, stateLabelIndex] = countryFromList.definitions
  const result = {
    country_code: countryData.isoCode,
    name: countryData.name === 'Netherlands The' ? 'The Netherlands' : countryData.name,
    labels: {
      postcode: postcodeDefinitions[postcodeLabelIndex],
      city: townCityDefinitions[cityLabelIndex],
      region: stateProvinceDefinitions[stateLabelIndex]
    }
  }

  return result
}

export const getAllCountries = () => {
  return Country.getAllCountries()
    .filter(countryData => {
      // get array of codes to check
      const supportedCountryCodes = supportedCountryList.map(c => c.country_code)

      const isCountrySupported = supportedCountryCodes.includes(countryData.isoCode)
      return isCountrySupported
    })
    .map(countryData => {
      const localCountryData = supportedCountryList.find(c => c.country_code === countryData.isoCode)
      return createCountry(localCountryData, countryData)
    })
}

export default supportedCountries
