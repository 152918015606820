import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'reactstrap'
import { updateCartItems } from '../../actions/index'
import Checkbox from '../general/CheckBox'
import dollar_price from '../general/DollarPrice'
import { setCreditsUsed, resolvePrice, resolveUnitPrice, setCheckoutLoading } from './checkoutFunctions'

const CheckoutCreditBalances = props => {
  const [checked, setChecked] = useState({
    coop: false,
    accountCredit: false,
    giftCard: false,
    lmsPoints: false
  })

  // when selection changes or any price changes, re-calculate and apply credits
  useEffect(() => {
    // apply credits according to selections
    props.setCheckoutLoading(true)
    calculateAndApplyCredits()
  }, [
    checked,
    props.checkout.subtotalReduction,
    props.checkout.shippingQuote,
    props.checkout.shippingReduction,
    props.checkout.productReduction,
    props.checkout.taxQuote
  ])

  // when creditsUsed changes, apply those credits to each product, then update cart items
  useEffect(() => {
    const { coopCredits, giftCard, accountCredits, lmsPoints } = props.checkout.creditsUsed
    // return unless any credits are to be used (check if any are > 0)
    if ([coopCredits, giftCard, accountCredits, lmsPoints].every(val => val === 0)) {
      return
    }
    // otherwise, apply credits to cart items
    const cartItemsWithAppliedCredits = applyCreditsToCartItems(
      props.cartItems,
      props.checkout.creditsUsed.coopCredits,
      props.checkout.creditsUsed.accountCredits,
      props.checkout.creditsUsed.giftCard,
      props.checkout.creditsUsed.lmsPoints
    )

    props.updateCartItems(cartItemsWithAppliedCredits)
  }, [
    props.checkout.creditsUsed,
    props.checkout.shippingReduction,
    props.checkout.shippingQuote,
    props.checkout.productReduction
  ])

  // automatically apply credits that are available to checkout
  useEffect(() => {
    const creditDefinition = [
      { userCredit: 'coop_credit', state: 'coop' },
      { userCredit: 'credit_note', state: 'accountCredit' },
      { userCredit: 'gift_card_credit', state: 'giftCard' },
      { userCredit: 'lms_points', state: 'lmsPoints' }
    ]
    const availableCredits = Object.entries(props.userCredits).filter(val => val[1].remaining > 0)

    availableCredits.length > 0 &&
      availableCredits.map(credit => {
        const selectedCredit = creditDefinition.find(val => val.userCredit === credit[0])
        setChecked({ ...checked, [selectedCredit.state]: true })
      })

    if (props.portal.use_lms_points !== true) {
      // if portal.use_lms_points is false but the user still has some balance, so hide it.
      setChecked({ ...checked, lmsPoints: false })
    }
  }, [props.userCredits])

  const calculateAndApplyCredits = () => {
    const coopEnabled = props.portal.coop_enabled && props.currentLocation.coop_enable !== false

    const totalCoopDeduction = coopEnabled ? getCoopTotal(props.cartItems, props.userCredits.coop_credit.remaining) : 0
    const totalCreditDeduction = getTotalCreditReduction(checked.coop ? totalCoopDeduction : 0)
    const totalGiftReduction = getTotalGiftReduction(
      (checked.coop ? totalCoopDeduction : 0) + (checked.accountCredit ? totalCreditDeduction : 0)
    )
    const totalLmsPointsReduction = getTotalLmsPointsReduction(
      (checked.coop ? totalCoopDeduction : 0) +
        (checked.accountCredit ? totalCreditDeduction : 0) +
        (checked.giftCard ? totalGiftReduction : 0)
    )

    props.setCreditsUsed({
      coopCredits: checked.coop ? totalCoopDeduction : 0,
      accountCredits: checked.accountCredit ? totalCreditDeduction : 0,
      giftCard: checked.giftCard ? totalGiftReduction : 0,
      lmsPoints: checked.lmsPoints ? totalLmsPointsReduction : 0
    })
  }

  // accepts cart items, returns cart items with updated priceData containing applied credits
  const applyCreditsToCartItems = (cartItems, coopCredits, accountCredits, giftCardCredits, lmsPointsCredits) => {
    // first, clear all discounts to start from a clean slate
    cartItems = clearAppliedCredits(cartItems)
    // get the desired credits to be used
    let coopCreditsToBeApplied = coopCredits
    let accountCreditsToBeApplied = accountCredits
    let giftCreditsToBeApplied = giftCardCredits
    let lmsPointsCreditsToBeApplied = 0
    if (props.portal.use_lms_points == true) {
      lmsPointsCreditsToBeApplied = lmsPointsCredits
    } else {
      lmsPointsCreditsToBeApplied = 0
    }

    return cartItems.map(cartItem => {
      const newPriceData = {
        ...cartItem.priceData,
        appliedCredits: {}
      }

      // calculate price before discounts so we know how much we can apply
      // let cartItemUnitPriceBeforeDiscount = resolveUnitPrice(newPriceData)
      const cartItemPriceBeforeDiscount = resolvePrice(newPriceData)

      // if there is a discount applied to subtotal, caluclate amount to apply to each product
      const perProductDiscount = props.checkout.subtotalReduction
        ? props.checkout.subtotalReduction / cartItems.length
        : 0 + props.checkout.productReduction
          ? props.checkout.productReduction / cartItems.length
          : 0

      // If credits can be applied, apply & save them for each type

      // * Co-Op *
      // If user wants to apply coop credits and priceData has coop_deduction_cents, (is eligible)
      // apply as many coopcredits as possible
      if (coopCreditsToBeApplied && newPriceData.coop_deduction_cents) {
        // get the total amount possible that can be applied
        let applicableCoopDeduction = newPriceData.coop_deduction_cents

        // If a discount is present, use the adjusted price and coop deduction
        if (perProductDiscount) {
          applicableCoopDeduction = newPriceData.adjusted_coop_deduction_cents
        }

        applicableCoopDeduction = applicableCoopDeduction * cartItem.quantity
        // if we have enough for the full deduction, apply it
        if (coopCreditsToBeApplied >= applicableCoopDeduction) {
          coopCreditsToBeApplied -= applicableCoopDeduction
          newPriceData.appliedCredits.coop = applicableCoopDeduction
        } else {
          // else apply our remaining credit
          newPriceData.appliedCredits.coop = coopCreditsToBeApplied
          coopCreditsToBeApplied -= coopCreditsToBeApplied
        }
      }

      // * Account Credits *
      // similar to above. If user wants to use acct credits,
      // apply and reduce running total
      if (accountCreditsToBeApplied) {
        // If coop credits were applied to this item,
        // only apply account credits to the remaining balance
        if (newPriceData.appliedCredits.coop) {
          const balanceAfterCoopDeduction = cartItemPriceBeforeDiscount - newPriceData.appliedCredits.coop
          // If we have enough acct credits to cover remaining balance, apply full amount
          // else simply use our remaining acct credits
          if (accountCreditsToBeApplied >= balanceAfterCoopDeduction) {
            accountCreditsToBeApplied -= balanceAfterCoopDeduction
            newPriceData.appliedCredits.accountCredits = balanceAfterCoopDeduction
          } else {
            accountCreditsToBeApplied -= accountCreditsToBeApplied
            newPriceData.appliedCredits.accountCredits = accountCreditsToBeApplied
          }
        } else {
          // same as above, except use full amount instead of amount after coop deduction
          if (accountCreditsToBeApplied >= cartItemPriceBeforeDiscount) {
            accountCreditsToBeApplied -= cartItemPriceBeforeDiscount
            newPriceData.appliedCredits.accountCredits = cartItemPriceBeforeDiscount
          } else {
            accountCreditsToBeApplied -= accountCreditsToBeApplied
            newPriceData.appliedCredits.accountCredits = accountCreditsToBeApplied
          }
        }
      }

      // * Gift Card Credit *
      if (giftCreditsToBeApplied) {
        // as above: if any discounts were already applied, only apply to remaining balance
        const balanceAfterCoopAndAccountCreditDeduction =
          cartItemPriceBeforeDiscount -
          (newPriceData.appliedCredits.accountCredits ? newPriceData.appliedCredits.accountCredits : 0) -
          (newPriceData.appliedCredits.coopCredits ? newPriceData.appliedCredits.coopCredits : 0)

        // follow same pattern as above
        if (giftCreditsToBeApplied > balanceAfterCoopAndAccountCreditDeduction) {
          giftCreditsToBeApplied -= balanceAfterCoopAndAccountCreditDeduction
          newPriceData.appliedCredits.giftCard = balanceAfterCoopAndAccountCreditDeduction
        } else {
          giftCreditsToBeApplied -= giftCreditsToBeApplied
          newPriceData.appliedCredits.giftCard = accountCreditsToBeApplied
        }
      }

      // * LMS Points Credit *
      if (lmsPointsCreditsToBeApplied) {
        // TODO
        // as above: if any discounts were already applied, only apply to remaining balance
        const balanceAfterallDeductions =
          cartItemPriceBeforeDiscount -
          (newPriceData.appliedCredits.accountCredits ? newPriceData.appliedCredits.accountCredits : 0) -
          (newPriceData.appliedCredits.coopCredits ? newPriceData.appliedCredits.coopCredits : 0) -
          (newPriceData.appliedCredits.giftCardCredits ? newPriceData.appliedCredits.giftCardCredits : 0)

        // follow same pattern as above
        if (lmsPointsCreditsToBeApplied > balanceAfterallDeductions) {
          lmsPointsCreditsToBeApplied -= balanceAfterallDeductions
          newPriceData.appliedCredits.lmsPoints = balanceAfterallDeductions
        } else {
          lmsPointsCreditsToBeApplied -= lmsPointsCreditsToBeApplied
          newPriceData.appliedCredits.lmsPoints = lmsPointsCreditsToBeApplied
        }
      }

      return {
        ...cartItem,
        priceData: newPriceData
      }
    })
  }

  const clearAppliedCredits = cartItems => {
    return cartItems.map(cartItem => {
      return {
        ...cartItem,
        priceData: {
          ...cartItem.priceData,
          appliedCredits: {}
        }
      }
    })
  }

  const handleCheckBox = val => {
    setChecked({ ...checked, [val]: !checked[val] })
  }

  // returns total amount of coopcredits applied to cart
  const getCoopTotal = (cartItems, coopCreditsToUse) => {
    const coopPercent = props.userCredits.coop_credit.percentage / 100
    const coopAvailable = coopCreditsToUse
    let deductionTotal = 0 // find total amount to be deducted using coop funds

    cartItems.map(product => {
      if (product.coop_enabled) {
        let productPrice = resolveUnitPrice(product.priceData)
        if (productPrice < 0) {
          productPrice = 0
        }
        const coopDeduction = productPrice * coopPercent
        const totalDeductionPrice = coopDeduction * product.quantity
        deductionTotal += totalDeductionPrice
      }
    })

    // return total deduction if user has enough credits,
    // else return all of available credits
    let applicableCoop = coopAvailable - deductionTotal >= 0 ? deductionTotal : coopAvailable

    // if coop deduction is greater than subtotal, only return amount that should be used
    const subtotalAfterReduction =
      props.subtotal - props.checkout.subtotalReduction < 0 ? 0 : props.subtotal - props.checkout.subtotalReduction
    if (applicableCoop >= subtotalAfterReduction) {
      applicableCoop = subtotalAfterReduction
    }
    return applicableCoop
  }

  // returns total account credits applied to cart
  const getTotalCreditReduction = totalCoopDeduction => {
    let subTotal = props.checkout.subtotal
    subTotal -= totalCoopDeduction
    const taxDelivery = props.checkout.taxQuote + props.checkout.shippingQuote
    const totalWithTaxDelivery =
      subTotal +
      taxDelivery -
      (props.checkout.subtotalReduction + props.checkout.shippingReduction) -
      (props.checkout.productReduction ? props.checkout.productReduction : 0)
    const totalCredit = props.userCredits.credit_note ? props.userCredits.credit_note.remaining : 0
    const creditDeduction = totalWithTaxDelivery - totalCredit <= 0 ? totalWithTaxDelivery : totalCredit
    return creditDeduction
  }

  const getTotalGiftReduction = totalCreditDeduction => {
    let subTotal = props.checkout.subtotal
    subTotal -= totalCreditDeduction
    if (subTotal <= 0) {
      return 0
    } else {
      const taxDelivery = props.checkout.taxQuote + props.checkout.shippingQuote
      // check: next line returning NaN?
      const totalWithTaxDelivery =
        subTotal +
        taxDelivery -
        (props.checkout.subtotalReduction ? props.checkout.subtotalReduction : 0) -
        (props.checkout.productReduction ? props.checkout.productReduction : 0)
      const totalGift = props.userCredits.gift_card_credit ? props.userCredits.gift_card_credit.remaining : 0
      const giftDeduction = totalWithTaxDelivery - totalGift <= 0 ? totalWithTaxDelivery : totalGift
      return giftDeduction
    }
  }

  const getTotalLmsPointsReduction = totalCreditDeduction => {
    let subTotal = props.checkout.subtotal
    subTotal -= totalCreditDeduction
    const taxDelivery = props.checkout.taxQuote + props.checkout.shippingQuote
    const totalWithTaxDelivery =
      subTotal +
      taxDelivery -
      (props.checkout.subtotalReduction + props.checkout.shippingReduction) -
      (props.checkout.productReduction ? props.checkout.productReduction : 0)
    const totalCredit = props.userCredits.lms_points ? props.userCredits.lms_points.remaining : 0
    const creditDeduction = totalWithTaxDelivery - totalCredit <= 0 ? totalWithTaxDelivery : totalCredit
    return creditDeduction
  }

  // If there are no credits, or if all credits are $0, return null
  if (
    !props.userCredits ||
    (props.userCredits.credit_note &&
      props.userCredits.credit_note.remaining === 0 &&
      props.checkout.creditsUsed.coopCredits === 0 &&
      props.userCredits.gift_card_credit &&
      props.userCredits.gift_card_credit.remaining === 0 &&
      props.checkout.creditsUsed.coopCredits === 0 &&
      props.userCredits.lms_points &&
      props.userCredits.lms_points.remaining === 0 &&
      props.checkout.creditsUsed.lmsPoints === 0 &&
      props.userCredits.coop_credit &&
      props.userCredits.coop_credit.remaining === 0 &&
      props.checkout.creditsUsed.coopCredits === 0)
  ) {
    return null
  }

  const colStyle = { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }
  const rowStyle = { cursor: 'pointer', borderRadius: '3px' }

  return (
    <div className="mt-4 box-shadow white-background">
      <div className="ckout-header">
        <h5>Available Credit Balances</h5>
        <p>Please select any that you wish to use</p>
      </div>

      <Container className="mb-20">
        <Row>
          <Col xs="12" className="px-30">
            {/* Coop Credits */}
            {props.portal.coop_enabled &&
            props.currentLocation.coop_enable &&
            ((props.userCredits.coop_credit.remaining > 0 &&
              props.checkout.creditsUsed.coopCredits === 0 &&
              props.youPay > 0) ||
              checked.coop) ? (
              <Row
                className={
                  checked.coop && props.cartItems.some(product => product.coop_enabled)
                    ? ' alert-success cart-flex pt-3 pb-3 border'
                    : ' cart-flex pt-3 pb-3 border'
                }
                style={
                  props.cartItems.some(product => product.coop_enabled)
                    ? rowStyle
                    : { backgroundColor: '#e0e0e0', borderRadius: '4px' }
                }
                onClick={() => handleCheckBox('coop')}
              >
                <Col sm="10">
                  <h6 className="m-0">
                    {props.portal.context_dictionary.coop_def}:{' '}
                    {dollar_price(props.userCredits.coop_credit.remaining - props.checkout.creditsUsed.coopCredits)}
                    {/* "Disable" row if there are no eligible Co-Op products in cart */}
                    {props.cartItems.some(product => product.coop_enabled) ? null : (
                      <p style={{ display: 'inline' }}>
                        &nbsp;&nbsp;<em>(No {props.portal.context_dictionary.coop_def} Eligible Products In Cart)</em>
                      </p>
                    )}
                  </h6>
                </Col>
                <Col sm="2" style={colStyle}>
                  <Checkbox
                    checked={checked.coop && props.cartItems.some(product => product.coop_enabled)}
                    onChange={() => handleCheckBox('coop')}
                  />
                </Col>
              </Row>
            ) : null}

            {/* Account Credits */}
            {(props.userCredits.credit_note.remaining > 0 &&
              props.checkout.creditsUsed.accountCredits === 0 &&
              props.youPay > 0) ||
            checked.accountCredit ? (
              <Row
                className={
                  checked.accountCredit ? ' alert-success cart-flex pt-3 pb-3 border' : ' cart-flex pt-3 pb-3 border'
                }
                style={rowStyle}
                onClick={() => handleCheckBox('accountCredit')}
              >
                <Col sm="10">
                  <h6 className="m-0">
                    {props.portal.context_dictionary.account_credit_def} Balance:&nbsp;
                    {dollar_price(props.userCredits.credit_note.remaining - props.checkout.creditsUsed.accountCredits)}
                  </h6>
                </Col>
                <Col sm="2" style={colStyle}>
                  <Checkbox checked={checked.accountCredit} onChange={() => handleCheckBox('accountCredit')} />
                </Col>
              </Row>
            ) : null}

            {/* Gift Card Credits */}
            {(props.userCredits.gift_card_credit.remaining > 0 &&
              props.checkout.creditsUsed.giftCard === 0 &&
              props.youPay > 0) ||
            checked.giftCard ? (
              <Row
                className={
                  checked.giftCard ? ' alert-success cart-flex pt-3 pb-3 border' : ' cart-flex pt-3 pb-3 border'
                }
                style={rowStyle}
                onClick={() => handleCheckBox('giftCard')}
              >
                <Col sm="10">
                  <h6 className="m-0">
                    Gift Card Balance:
                    {dollar_price(props.userCredits.gift_card_credit.remaining - props.checkout.creditsUsed.giftCard)}
                  </h6>
                </Col>
                <Col sm="2" style={colStyle}>
                  <Checkbox checked={checked.giftCard} onChange={() => handleCheckBox('giftCard')} />
                </Col>
              </Row>
            ) : null}

            {/* Lms Points Credits */}
            {(props.portal.use_lms_points === true &&
              props.userCredits.lms_points.remaining > 0 &&
              props.checkout.creditsUsed.lmsPoints === 0 &&
              props.youPay > 0) ||
            checked.lmsPoints ? (
              <Row
                className={
                  checked.lmsPoints ? ' alert-success cart-flex pt-3 pb-3 border' : ' cart-flex pt-3 pb-3 border'
                }
                style={rowStyle}
                onClick={() => handleCheckBox('lmsPoints')}
              >
                <Col sm="10">
                  <h6 className="m-0">
                    Points:&nbsp;
                    {dollar_price(props.userCredits.lms_points.remaining - props.checkout.creditsUsed.lmsPoints)}
                  </h6>
                </Col>
                <Col sm="2" style={colStyle}>
                  <Checkbox checked={checked.lmsPoints} onChange={() => handleCheckBox('lmsPoints')} />
                </Col>
              </Row>
            ) : null}

            {props.youPay > 0 ? null : (
              <div className="alert alert-success alert-payment mt-4">No payment required as balance due is zero.</div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userCredits: state.userCredits,
    subtotal: state.checkout.subtotal,
    youPay: state.checkout.youPay,
    portal: state.portal,
    currentLocation: state.currentLocation,
    cartItems: state.cartItems,
    checkout: state.checkout
  }
}

export default connect(mapStateToProps, { setCreditsUsed, updateCartItems, setCheckoutLoading })(CheckoutCreditBalances)
